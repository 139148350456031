<template>
	<div class="param11 drag" v-draw>
		<div class="param11-header">
			<span class="param11-header-title">{{projectData.project}}_参数21</span>
			<div class="param11-header-icon" @click="closeCompon">
				<img src="~@/assets/images/close.png" style="width: 100%; height: auto" />
			</div>
		</div>
		<div class="param11-tab">
			<div class="param11-tab-tit">2#炉控制参数</div>
			<div class="tab-row flex">
				<div class="row1">
					<div class="row1-2 fl">
						<div class="row1-1 fl">
							<div class="title1" @click="toIpt(infoList.V_RS2.QHSJ_B2,'初期增加量', 'QHSJ_B2', 'V_RS2')">初期增加量
							</div>
							<div class="title11" style="border-bottom: solid 1px #bbd6e5;">{{infoList.V_RS2.QHSJ_B2}}℃
							</div>
							<div class="title1">调整时间</div>
							<div class="title11" @click="toIpt(infoList.V_RS2.YWZJSJ_B2,'调整时间', 'YWZJSJ_B2', 'V_RS2')">
								{{infoList.V_RS2.YWZJSJ_B2}}m</div>
						</div>
						<div class="row1-3-1 fl">
							<div class="title1">预测烟温</div>
							<div class="title11" @click="toCompon(2,'YCFQWD_B2','V_RS2','YCFQWD_B2_WF','2#炉预测废气温度')">
								{{infoList.V_RS2.YCFQWD_B2}}</div>
						</div>
						<div class="row1-4-1 fl">
							<div class="title1">煤气设定</div>
							<div class="title11"
								@click="toIpt(infoList.V_SOUREC.FQSP_B2,'煤气设定', 'FQSP_B2', 'V_SOUREC')">
								{{infoList.V_SOUREC.FQSP_B2}}</div>
						</div>
						<div class="row1-5 fl">
							<div class="fl" style="margin-right:8px;margin-left:2px">
								<div class="title1">初始强度</div>
								<div class="title11" @click="toIpt(infoList.V_RS2.TGY_B2,'初始强度', 'TGY_B2', 'V_RS2')">
									{{infoList.V_RS2.TGY_B2}}</div>
							</div>
							<div class="fl">
								<div class="title1">修正常数</div>
								<div class="title11"
									@click="toIpt(infoList.V_RS2.JBKG02_B2,'修正常数', 'JBKG02_B2', 'V_RS2')">
									{{infoList.V_RS2.JBKG02_B2}}</div>
							</div>
						</div>
						<div class="row1-4 fl">
							<div class="buttonx"
								:style="{background:(!infoList.V_RS__p__RSFB2__p__XK04.TS ?'#2AFC30':'red')}"
								@click="infoList.V_RS__p__RSFB2__p__XK04 ? toDetail(1,'XKB04','V_RS__p__RSFB2__p__XK04','','2#废气温度调节先控' ): ''">
								X</div>
							<div class="title2" @click="toCompon(2,'MQSP_B2','V_RS2','MQSP_B2_WF','2#煤气设定值')">
								{{infoList.V_RS2.MQSP_B2}}</div>
							<div class="row1-6">
								<div class="title1">烧炉强度</div>
								<div class="title11"
									@click="toIpt(infoList.V_RS2.KQSP02_B2,'燃烧强度', 'KQSP02_B2', 'V_RS2')">
									{{infoList.V_RS2.KQSP02_B2}}</div>
							</div>
							<div class="row1-6" style="margin-top:30px">
								<div class="title1">优化风煤比</div>
								<div class="title11"
									@click="toIpt(infoList.V_BCS2.YHFMB_B2,'优化风煤比', 'YHFMB_B2', 'V_BCS2')">
									{{infoList.V_BCS2.YHFMB_B2}}</div>
							</div>
							<div class="row1-7">
								<div class="title1">协调减少量</div>
								<div class="title11" @click="toCompon(2,'KQSP01_B2','V_RS2','KQSP01_B2_WF','2#炉优先烧炉减少煤气量')">
									{{infoList.V_RS2.KQSP01_B2}}</div>
							</div>
							<div class="row1-8">
								<div class="title1">基本强度</div>
								<div class="title11"
									@click="toIpt(infoList.V_RS2.JBMQL_B2,'基本强度', 'JBMQL_B2', 'V_RS2')">
									{{infoList.V_RS2.JBMQL_B2}}</div>
							</div>
							<div class="row1-9">
								<div class="title1">单烧增量</div>
								<div class="title11" @click="toIpt(infoList.V_RS2.DSZL_B2,'单烧增量', 'DSZL_B2', 'V_RS2')">
									{{infoList.V_RS2.DSZL_B2}}</div>
							</div>
							<div class="row1-10">
								<div class="title1">保留系数</div>
								<div class="title11" @click="toIpt(infoList.V_RS2.FSPM_B2,'保留系数', 'FSPM_B2', 'V_RS2')">
									{{infoList.V_RS2.FSPM_B2}}</div>
							</div>
							<div class="row1-7">
								<div class="title1" style="margin-top:200px">废弃含氧量</div>
								<div class="title11" @click="toCompon(2,'TE_14_B2','V_SOUREC','TE_14_B2_WF','2#热风炉废气含氧量')">
									{{infoList.V_SOUREC.TE_14_B2}}</div>
							</div>
							<div class="row1-7" style="margin-left:60px;margin-top:-110px">
								<div class="title1">含氧量设定</div>
								<div class="title11"
									@click="toIpt(infoList.V_SOUREC.ZJZQ_B2,'含氧量设定', 'ZJZQ_B2', 'V_SOUREC')">
									{{infoList.V_SOUREC.ZJZQ_B2}}</div>
							</div>
							<div class="row1-7" style="margin-left:-60px;margin-top:-120px">
								<div class="buttonx"
									:style="{background:(!infoList.V_RS__p__RSFB2__p__XK05.TS ?'#2AFC30':'red')}"
									@click="infoList.V_RS__p__RSFB2__p__XK05 ? toDetail(1,'XKB05','V_RS__p__RSFB2__p__XK05','','2#拱顶温度调节空燃比先控' ): ''">
									X</div>
							</div>
							<div class="row1-11">
								<div class="title1">顶温下降调配比系数</div>
								<div class="title11"
									@click="toIpt(infoList.V_RS2.GDBL_B2,'顶温下降调配比系数', 'GDBL_B2', 'V_RS2')">
									{{infoList.V_RS2.GDBL_B2}}</div>
							</div>
							<div class="row1-12">
								<div class="title1">干预风煤比</div>
								<div class="title11" @click="toCompon(2,'AV','V_RS__p__RSFB2__p__XK05','XKB05_AV_WF','2#拱顶温度调节空燃比XK总输出')">
									{{infoList.V_RS__p__RSFB2__p__XK05.AV ==0 ? '0.00' : infoList.V_RS__p__RSFB2__p__XK05.AV}}
								</div>
							</div>
							<div class="buttonr "
								:style="{background:(!infoList.V_RS__p__RSFB2__p__RSF1.TS ?'#2AFC30':'red')}"
								@click="infoList.V_RS__p__RSFB2__p__RSF1 ? toDetail(2,'RSFB2','V_RS__p__RSFB2__p__RSF1','','2#废气含氧量高调节' ): ''">
								R</div>
							<div class="row1-13">
								<div class="title11" @click="toCompon(2,'AV','V_RS__p__RSFB2__p__RSF1','RSFB2_AV_WF','2#废气含氧量低调节AV')">
									{{infoList.V_RS__p__RSFB2__p__RSF1.AV}}</div>
							</div>

							<div class="buttonr " style="margin-top:55px"
								:style="{background:(!infoList.V_RS__p__RSFB2__p__RSF2.TS ?'#2AFC30':'red')}"
								@click="infoList.V_RS__p__RSFB2__p__RSF2 ? toDetail(2,'RSFB2','V_RS__p__RSFB2__p__RSF2','','2#废气含氧量低调节' ): ''">
								R</div>
							<div class="row1-13" style="margin-top:-25px">
								<div class="title11" @click="toCompon(2,'AV','V_RS__p__RSFB2__p__RSF2','RSFB2_AV_WF','2#废气含氧量低调节AV')">
									{{infoList.V_RS__p__RSFB2__p__RSF2.AV}}</div>
							</div>
							<div class="startbutton"
								@click="toIpt(infoList.V_RS2.SPQHSJ_B2,'按钮', 'SPQHSJ_B2', 'V_RS2')">
								{{infoList.V_RS2.SPQHSJ_B2 ? '启动' : '屏蔽'}}
							</div>
							<div class="row1-14">
								<div class="title1">空气流量设定值</div>
								<div class="title11" @click="toCompon(2,'KQSP_B2','V_RS2','KQSP_B2_WF','2#空气设定值')">
									{{infoList.V_RS2.KQSP_B2}}</div>
							</div>
							<div class="buttonx1 "
								:style="{background:(!infoList.V_RS__p__RSFB2__p__XK02.TS ?'#2AFC30':'red')}"
								@click="infoList.V_RS__p__RSFB2__p__XK02 ? toDetail(1,'XKB02','V_RS__p__RSFB2__p__XK02','','2#空气流量先控' ): ''">
								X</div>
							<div class="row1-15">
								<div class="title11" @click="toCompon(2,'AV','V_RS__p__RSFB2__p__XK02','XKB02_AV_WF','2#空气流量XK总输出')">
									{{infoList.V_RS__p__RSFB2__p__XK02.AV}}</div>
							</div>
							<div class="otherhis" @click="toCompon(2, 'LINGPAI', 'V_RS__p__RSFB2', 'LINGPAI2_WF','2#令牌')">
								其他情况
							</div>
							<div class="row1-1 fl" style="margin-left:610px">
								<div class="title1">空气上限</div>
								<div class="title11" style="border-bottom: solid 1px #bbd6e5;"
									@click="toIpt(infoList.V_HLLIM.HLLIMH22,'空气上限', 'HLLIMH22', 'V_HLLIM')">
									{{infoList.V_HLLIM.HLLIMH22}}</div>
								<div class="title1">空气下限</div>
								<div class="title11"
									@click="toIpt(infoList.V_HLLIM.HLLIML22,'空气下限', 'HLLIML22', 'V_HLLIM')">
									{{infoList.V_HLLIM.HLLIML22}}</div>
								<div class="row1-3 fl" style="margin-left:90px;margin-top:-60px">
									<div class="title1">空气流量</div>
									<div class="title11"
										@click="toCompon(2,'PV','V_RS__p__RSFB2__p__XK02','XKB02_PV_WF','2#空气流量XK测量值')">
										{{infoList.V_RS__p__RSFB2__p__XK02.PV}}</div>
								</div>
								<div class="startbutton2" style="position:relative;">
									{{infoList.V_OTHER1.SEL206 ? '启用补偿' :'停止补偿'}}
								</div>
								<div class="row1-16">
									<div>
										<div class="div1 fl">初始阀位增量</div>
										<div class="div2 fl"
											@click="toIpt(infoList.V_GONGGONG.CSKQFWZL_B2,'初始阀位增量', 'CSKQFWZL_B2', 'V_GONGGONG')">
											{{infoList.V_GONGGONG.CSKQFWZL_B2}}</div>
									</div>
									<div>
										<div class="div11 fl">空压前馈系数</div>
										<div class="div22 fl"
											@click="toIpt(infoList.V_RS2.KM_KQ_B2,'空压前馈系数', 'KM_KQ_B2', 'V_RS2')">
											{{infoList.V_RS2.KM_KQ_B2}}</div>
									</div>
									<div>
										<div class="div111 fl">前馈输出</div>
										<div class="div222 fl" @click="toCompon(2,'FV_HH_B2','V_RS2','FV_HH_B2_WF','2#炉空气阀位前馈量')">
											{{infoList.V_RS2.FV_HH_B2}}</div>
									</div>
								</div>
								<div class="startbutton3" @click="isComShow=true">优化参数画面</div>
							</div>
							<div class="row1-14" style="margin-top:110px">
								<div class="title1">煤气流量设定值</div>
								<div class="title11" @click="toCompon(2,'MQSP_B2','V_RS2','MQSP_B2_WF','2#煤气设定值')">
									{{infoList.V_RS2.MQSP_B2}}</div>
							</div>
							<div class="buttonx1 "
								:style="{background:(!infoList.V_RS__p__RSFB2__p__XK01.TS ?'#2AFC30':'red')}"
								@click="infoList.V_RS__p__RSFB2__p__XK01 ? toDetail(1,'XKB01','V_RS__p__RSFB2__p__XK01','','2#煤气流量先控' ): ''">
								X</div>
							<div class="row1-15">
								<div class="title11" @click="toCompon(2,'AV','V_RS__p__RSFB2__p__XK01','XKB01_AV_WF','2#煤气流量XK总输出')">
									{{infoList.V_RS__p__RSFB2__p__XK01.AV}}</div>
							</div>
							<div class="row1-1 fl" style="margin-left:610px;margin-top:-10px">
								<div class="title1">煤气上限</div>
								<div class="title11" style="border-bottom: solid 1px #bbd6e5;"
									@click="toIpt(infoList.V_HLLIM.HLLIMH21,'煤气上限', 'HLLIMH21', 'V_HLLIM')">
									{{infoList.V_HLLIM.HLLIMH21}}</div>
								<div class="title1">煤气下限</div>
								<div class="title11"
									@click="toIpt(infoList.V_HLLIM.HLLIML11,'煤气下限', 'HLLIML11', 'V_HLLIM')">
									{{infoList.V_HLLIM.HLLIML11}}</div>
								<div class="row1-3 fl" style="margin-left:90px;margin-top:-60px">
									<div class="title1">煤气流量</div>
									<div class="title11"
										@click="toCompon(2,'PV','V_RS__p__RSFB2__p__XK01','XKB01_PV_WF','2#煤气流量XK测量值')">
										{{infoList.V_RS__p__RSFB2__p__XK01.PV}}</div>
								</div>
								<div class="startbutton2" style="position:relative;">
									{{infoList.V_OTHER1.SEL205 ? '启用补偿' :'停止补偿'}}
								</div>
								<div class="row1-16">
									<div>
										<div class="div1 fl">初始阀位增量</div>
										<div class="div2 fl"
											@click="toIpt(infoList.V_GONGGONG.CSMQFWZL_B2,'初始阀位增量', 'CSMQFWZL_B2', 'V_GONGGONG')">
											{{infoList.V_GONGGONG.CSMQFWZL_B2}}</div>
									</div>
									<div>
										<div class="div11 fl">煤压前馈系数</div>
										<div class="div22 fl"
											@click="toIpt(infoList.V_BCS2.ZJSJ_B2,'煤压前馈系数', 'ZJSJ_B2', 'V_BCS2')">
											{{infoList.V_BCS2.ZJSJ_B2}}</div>
									</div>
									<div>
										<div class="div111 fl">前馈输出</div>
										<div class="div222 fl" @click="toCompon(2,'ZJC_B2','V_BCS2','ZJC_B2_WF','专家常数')">
											{{infoList.V_BCS2.ZJC_B2}}</div>
									</div>
								</div>
							</div>
							<div class="row1-17">
								<div class="row-17-1 fl">煤气</div>
								<div class="row-17-2 fl">
									<div>阀位</div>
									<div>系数</div>
									<div>压力</div>
									<div>系数</div>
								</div>
								<div class="row-17-2 fl">
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC1.X__ll__1__rr__,'阀位', 'X__ll__1__rr__', 'V_RS__p__RSFB2__p__HSCHARC1')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC1.X__ll__1__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC1.Y__ll__1__rr__,'系数', 'Y__ll__1__rr__', 'V_RS__p__RSFB2__p__HSCHARC1')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC1.Y__ll__1__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC2.X__ll__1__rr__,'压力', 'X__ll__1__rr__', 'V_RS__p__RSFB2__p__HSCHARC2')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC2.X__ll__1__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC2.Y__ll__1__rr__,'系数', 'Y__ll__1__rr__', 'V_RS__p__RSFB2__p__HSCHARC2')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC2.Y__ll__1__rr__}}</div>
								</div>
								<div class="row-17-2 fl">
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC1.X__ll__2__rr__,'阀位', 'X__ll__2__rr__', 'V_RS__p__RSFB2__p__HSCHARC1')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC1.X__ll__2__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC1.Y__ll__2__rr__,'系数', 'Y__ll__2__rr__', 'V_RS__p__RSFB2__p__HSCHARC1')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC1.Y__ll__2__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC2.X__ll__2__rr__,'压力', 'X__ll__2__rr__', 'V_RS__p__RSFB2__p__HSCHARC2')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC2.X__ll__2__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC2.Y__ll__2__rr__,'系数', 'Y__ll__2__rr__', 'V_RS__p__RSFB2__p__HSCHARC2')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC2.Y__ll__2__rr__}}</div>
								</div>
								<div class="row-17-2 fl">
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC1.X__ll__3__rr__,'阀位', 'X__ll__3__rr__', 'V_RS__p__RSFB2__p__HSCHARC1')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC1.X__ll__3__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC1.Y__ll__3__rr__,'系数', 'Y__ll__3__rr__', 'V_RS__p__RSFB2__p__HSCHARC1')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC1.Y__ll__3__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC2.X__ll__3__rr__,'压力', 'X__ll__3__rr__', 'V_RS__p__RSFB2__p__HSCHARC2')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC2.X__ll__3__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC2.Y__ll__3__rr__,'系数', 'Y__ll__3__rr__', 'V_RS__p__RSFB2__p__HSCHARC2')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC2.Y__ll__3__rr__}}</div>
								</div>
								<div class="row-17-2 fl">
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC1.X__ll__4__rr__,'阀位', 'X__ll__4__rr__', 'V_RS__p__RSFB2__p__HSCHARC1')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC1.X__ll__4__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC1.Y__ll__4__rr__,'系数', 'Y__ll__4__rr__', 'V_RS__p__RSFB2__p__HSCHARC1')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC1.Y__ll__4__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC2.X__ll__4__rr__,'压力', 'X__ll__4__rr__', 'V_RS__p__RSFB2__p__HSCHARC2')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC2.X__ll__4__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC2.Y__ll__4__rr__,'系数', 'Y__ll__4__rr__', 'V_RS__p__RSFB2__p__HSCHARC2')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC2.Y__ll__4__rr__}}</div>
								</div>
								<div class="row-17-2 fl">
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC1.X__ll__5__rr__,'阀位', 'X__ll__5__rr__', 'V_RS__p__RSFB2__p__HSCHARC1')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC1.X__ll__5__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC1.Y__ll__5__rr__,'系数', 'Y__ll__5__rr__', 'V_RS__p__RSFB2__p__HSCHARC1')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC1.Y__ll__5__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC2.X__ll__5__rr__,'压力', 'X__ll__5__rr__', 'V_RS__p__RSFB2__p__HSCHARC2')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC2.X__ll__5__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC2.Y__ll__5__rr__,'系数', 'Y__ll__5__rr__', 'V_RS__p__RSFB2__p__HSCHARC2')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC2.Y__ll__5__rr__}}</div>
								</div>
								<div class="row-17-2 fl">
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC1.X__ll__6__rr__,'阀位', 'X__ll__6__rr__', 'V_RS__p__RSFB2__p__HSCHARC1')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC1.X__ll__6__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC1.Y__ll__6__rr__,'系数', 'Y__ll__6__rr__', 'V_RS__p__RSFB2__p__HSCHARC1')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC1.Y__ll__6__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC2.X__ll__6__rr__,'压力', 'X__ll__6__rr__', 'V_RS__p__RSFB2__p__HSCHARC2')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC2.X__ll__6__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC2.Y__ll__6__rr__,'系数', 'Y__ll__6__rr__', 'V_RS__p__RSFB2__p__HSCHARC2')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC2.Y__ll__6__rr__}}</div>
								</div>
								<div class="row-17-2 fl">
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC1.X__ll__7__rr__,'阀位', 'X__ll__7__rr__', 'V_RS__p__RSFB2__p__HSCHARC1')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC1.X__ll__7__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC1.Y__ll__7__rr__,'系数', 'Y__ll__7__rr__', 'V_RS__p__RSFB2__p__HSCHARC1')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC1.Y__ll__7__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC2.X__ll__7__rr__,'压力', 'X__ll__7__rr__', 'V_RS__p__RSFB2__p__HSCHARC2')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC2.X__ll__7__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC2.Y__ll__7__rr__,'系数', 'Y__ll__7__rr__', 'V_RS__p__RSFB2__p__HSCHARC2')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC2.Y__ll__7__rr__}}</div>
								</div>
								<div class="row-17-2 fl">
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC1.X__ll__8__rr__,'阀位', 'X__ll__8__rr__', 'V_RS__p__RSFB2__p__HSCHARC1')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC1.X__ll__8__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC1.Y__ll__8__rr__,'系数', 'Y__ll__8__rr__', 'V_RS__p__RSFB2__p__HSCHARC1')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC1.Y__ll__8__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC2.X__ll__8__rr__,'压力', 'X__ll__8__rr__', 'V_RS__p__RSFB2__p__HSCHARC2')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC2.X__ll__8__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC2.Y__ll__8__rr__,'系数', 'Y__ll__8__rr__', 'V_RS__p__RSFB2__p__HSCHARC2')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC2.Y__ll__8__rr__}}</div>
								</div>
								<div class="row-17-2 fl">
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC1.X__ll__9__rr__,'阀位', 'X__ll__9__rr__', 'V_RS__p__RSFB2__p__HSCHARC1')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC1.X__ll__9__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC1.Y__ll__9__rr__,'系数', 'Y__ll__9__rr__', 'V_RS__p__RSFB2__p__HSCHARC1')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC1.Y__ll__9__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC2.X__ll__9__rr__,'压力', 'X__ll__9__rr__', 'V_RS__p__RSFB2__p__HSCHARC2')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC2.X__ll__9__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC2.Y__ll__9__rr__,'系数', 'Y__ll__9__rr__', 'V_RS__p__RSFB2__p__HSCHARC2')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC2.Y__ll__9__rr__}}</div>
								</div>
								<div class="row-17-3 fl">
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC1.X__ll__10__rr__,'阀位', 'X__ll__10__rr__', 'V_RS__p__RSFB2__p__HSCHARC1')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC1.X__ll__10__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC1.Y__ll__10__rr__,'系数', 'Y__ll__10__rr__', 'V_RS__p__RSFB2__p__HSCHARC1')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC1.Y__ll__10__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC2.X__ll__10__rr__,'压力', 'X__ll__10__rr__', 'V_RS__p__RSFB2__p__HSCHARC2')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC2.X__ll__10__rr__}}</div>
									<div
										@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC2.Y__ll__10__rr__,'系数', 'Y__ll__10__rr__', 'V_RS__p__RSFB2__p__HSCHARC2')">
										{{infoList.V_RS__p__RSFB2__p__HSCHARC2.Y__ll__10__rr__}}</div>
								</div>
							</div>
							<div class="row1-19">
								<div class="row-19-1 fl">空气</div>
								<div class="row-19-2 fl" style="margin-left:35px;width:35px">
									<div>阀位</div>
									<div>系数</div>
									<div>压力</div>
									<div>系数</div>
								</div>
								<!-- <div class="row-19-2 fl " style="margin-left:30px">
                   <div>1</div>
                   <div>1</div>
                   <div>1</div>
                   <div>1</div>
               </div> -->
								<div class="flex justify-content-cen" style="float:left">
									<div class="row-19-2 fl ">
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC3.X__ll__1__rr__,'阀位', 'X__ll__1__rr__', 'V_RS__p__RSFB2__p__HSCHARC3')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC3.X__ll__1__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC3.Y__ll__1__rr__,'系数', 'Y__ll__1__rr__', 'V_RS__p__RSFB2__p__HSCHARC3')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC3.Y__ll__1__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC4.X__ll__1__rr__,'压力', 'X__ll__1__rr__', 'V_RS__p__RSFB2__p__HSCHARC4')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC4.X__ll__1__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC4.Y__ll__1__rr__,'系数', 'Y__ll__1__rr__', 'V_RS__p__RSFB2__p__HSCHARC4')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC4.Y__ll__1__rr__}}</div>
									</div>
									<div class="row-19-2 fl ">
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC3.X__ll__2__rr__,'阀位', 'X__ll__2__rr__', 'V_RS__p__RSFB2__p__HSCHARC3')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC3.X__ll__2__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC3.Y__ll__2__rr__,'系数', 'Y__ll__2__rr__', 'V_RS__p__RSFB2__p__HSCHARC3')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC3.Y__ll__2__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC4.X__ll__2__rr__,'压力', 'X__ll__2__rr__', 'V_RS__p__RSFB2__p__HSCHARC4')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC4.X__ll__2__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC4.Y__ll__2__rr__,'系数', 'Y__ll__2__rr__', 'V_RS__p__RSFB2__p__HSCHARC4')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC4.Y__ll__2__rr__}}</div>
									</div>
									<div class="row-19-2 fl">
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC3.X__ll__3__rr__,'阀位', 'X__ll__3__rr__', 'V_RS__p__RSFB2__p__HSCHARC3')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC3.X__ll__3__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC3.Y__ll__3__rr__,'系数', 'Y__ll__3__rr__', 'V_RS__p__RSFB2__p__HSCHARC3')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC3.Y__ll__3__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC4.X__ll__3__rr__,'压力', 'X__ll__3__rr__', 'V_RS__p__RSFB2__p__HSCHARC4')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC4.X__ll__3__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC4.Y__ll__3__rr__,'系数', 'Y__ll__3__rr__', 'V_RS__p__RSFB2__p__HSCHARC4')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC4.Y__ll__3__rr__}}</div>
									</div>
									<div class="row-19-2 fl ">
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC3.X__ll__4__rr__,'阀位', 'X__ll__4__rr__', 'V_RS__p__RSFB2__p__HSCHARC3')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC3.X__ll__4__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC3.Y__ll__4__rr__,'系数', 'Y__ll__4__rr__', 'V_RS__p__RSFB2__p__HSCHARC3')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC3.Y__ll__4__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC4.X__ll__4__rr__,'压力', 'X__ll__4__rr__', 'V_RS__p__RSFB2__p__HSCHARC4')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC4.X__ll__4__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC4.Y__ll__4__rr__,'系数', 'Y__ll__4__rr__', 'V_RS__p__RSFB2__p__HSCHARC4')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC4.Y__ll__4__rr__}}</div>
									</div>
									<div class="row-19-2 fl ">
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC3.X__ll__5__rr__,'阀位', 'X__ll__5__rr__', 'V_RS__p__RSFB2__p__HSCHARC3')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC3.X__ll__5__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC3.Y__ll__5__rr__,'系数', 'Y__ll__5__rr__', 'V_RS__p__RSFB2__p__HSCHARC3')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC3.Y__ll__5__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC4.X__ll__5__rr__,'压力', 'X__ll__5__rr__', 'V_RS__p__RSFB2__p__HSCHARC4')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC4.X__ll__5__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC4.Y__ll__5__rr__,'系数', 'Y__ll__5__rr__', 'V_RS__p__RSFB2__p__HSCHARC4')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC4.Y__ll__5__rr__}}</div>
									</div>
									<div class="row-19-2 fl ">
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC3.X__ll__6__rr__,'阀位', 'X__ll__6__rr__', 'V_RS__p__RSFB2__p__HSCHARC3')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC3.X__ll__6__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC3.Y__ll__6__rr__,'系数', 'Y__ll__6__rr__', 'V_RS__p__RSFB2__p__HSCHARC3')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC3.Y__ll__6__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC4.X__ll__6__rr__,'压力', 'X__ll__6__rr__', 'V_RS__p__RSFB2__p__HSCHARC4')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC4.X__ll__6__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC4.Y__ll__6__rr__,'系数', 'Y__ll__6__rr__', 'V_RS__p__RSFB2__p__HSCHARC4')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC4.Y__ll__6__rr__}}</div>
									</div>
									<div class="row-19-2 fl ">
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC3.X__ll__7__rr__,'阀位', 'X__ll__7__rr__', 'V_RS__p__RSFB2__p__HSCHARC3')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC3.X__ll__7__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC3.Y__ll__7__rr__,'系数', 'Y__ll__7__rr__', 'V_RS__p__RSFB2__p__HSCHARC3')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC3.Y__ll__7__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC4.X__ll__7__rr__,'压力', 'X__ll__7__rr__', 'V_RS__p__RSFB2__p__HSCHARC4')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC4.X__ll__7__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC4.Y__ll__7__rr__,'系数', 'Y__ll__7__rr__', 'V_RS__p__RSFB2__p__HSCHARC4')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC4.Y__ll__7__rr__}}</div>
									</div>
									<div class="row-19-2 fl ">
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC3.X__ll__8__rr__,'阀位', 'X__ll__8__rr__', 'V_RS__p__RSFB2__p__HSCHARC3')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC3.X__ll__8__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC3.Y__ll__8__rr__,'系数', 'Y__ll__8__rr__', 'V_RS__p__RSFB2__p__HSCHARC3')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC3.Y__ll__8__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC4.X__ll__8__rr__,'压力', 'X__ll__8__rr__', 'V_RS__p__RSFB2__p__HSCHARC4')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC4.X__ll__8__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC4.Y__ll__8__rr__,'系数', 'Y__ll__8__rr__', 'V_RS__p__RSFB2__p__HSCHARC4')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC4.Y__ll__8__rr__}}</div>
									</div>
									<div class="row-19-2 fl ">
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC3.X__ll__9__rr__,'阀位', 'X__ll__9__rr__', 'V_RS__p__RSFB2__p__HSCHARC3')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC3.X__ll__9__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC3.Y__ll__9__rr__,'系数', 'Y__ll__9__rr__', 'V_RS__p__RSFB2__p__HSCHARC3')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC3.Y__ll__9__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC4.X__ll__9__rr__,'压力', 'X__ll__9__rr__', 'V_RS__p__RSFB2__p__HSCHARC4')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC4.X__ll__9__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC4.Y__ll__9__rr__,'系数', 'Y__ll__9__rr__', 'V_RS__p__RSFB2__p__HSCHARC4')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC4.Y__ll__9__rr__}}</div>
									</div>
									<div class="row-19-3 fl ">
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC3.X__ll__10__rr__,'阀位', 'X__ll__10__rr__', 'V_RS__p__RSFB2__p__HSCHARC3')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC3.X__ll__10__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC3.Y__ll__10__rr__,'系数', 'Y__ll__10__rr__', 'V_RS__p__RSFB2__p__HSCHARC3')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC3.Y__ll__10__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC4.X__ll__10__rr__,'压力', 'X__ll__10__rr__', 'V_RS__p__RSFB2__p__HSCHARC4')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC4.X__ll__10__rr__}}</div>
										<div
											@click="toIpt(infoList.V_RS__p__RSFB2__p__HSCHARC4.Y__ll__10__rr__,'系数', 'Y__ll__10__rr__', 'V_RS__p__RSFB2__p__HSCHARC4')">
											{{infoList.V_RS__p__RSFB2__p__HSCHARC4.Y__ll__10__rr__}}</div>
									</div>
								</div>

							</div>
						</div>

					</div>


				</div>


			</div>
		</div>
		<inputVal ref="inputVal" @getTreeData='DataJson(arguments)'></inputVal>
		 <Historical v-if="isHshow" @sendStatus='isHshow=false' :historyname='historyname' :node='node' :Lkname='Lkname'
			:chName="chName" :infoList='infoList'></Historical>
		<Firstcontrol :infoList='infoList' :isIndex='isIndex' :historyname='Firstcontrolname'
			:titname='Firstcontroltitname' :node='Firstcontrolnode' v-if="isFshow" @sendStatus='isFshow=false'>
		</Firstcontrol>
		<Rsf v-if="isRshow" @sendStatus='isRshow=false' :titname='Rsftitname' :infoList='infoList'
			:historyname='Rsfname' :node='Rsfnode'></Rsf>
		<ParameterYh v-if="isComShow" :infoList='infoList' @sendStatus='isshowfase'></ParameterYh>
	</div>
</template>
<script>
	import inputVal from "./inputVal"; //输入框组件
	import Historical from "@/components/Historical.vue"; //历史趋势
	import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
	import Rsf from "@/components/RSF.vue"; //阮伺服组件
	import ParameterYh from "@/components/ParameterYh.vue"; //参数YH组件
	export default {
		name: "Param21",
		props: {
			infoList: {
				type: Object,
				default: {}, // 默认值
			},
		},
		components: {
			inputVal,
			Historical,
			Firstcontrol,
			Rsf,
			ParameterYh
		},
		data: () => {
			return {
				chName: '',
				isComShow: false,
				isHshow: false,
				isMshow: false,
				isRshow: false,
				historyname: "",
				node: "",
				Lkname: "",
				isIndex: "",
				Firstcontrolname: "",
				Firstcontroltitname: "",
				Firstcontrolnode: "",
				isFshow: false,
				Rsfname: "",
				Rsfnode: "",
				Rsftitname: "",
				authInfo: '',
				UserInfo: '',
				spotArr: [],
				grouptime: null,
			};
		},
		watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n
				},
				deep: true // 深度监听父组件传过来对象变化
			},
		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			this.authInfo = JSON.parse(localStorage.getItem("autharr"))
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
		},
		methods: {
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				console.log(num, mark, node, name)
				return
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {

						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			// 打开先控多窗口
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0]
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error('暂无调试权限');
					}
				} else {
					let pathname = ""
					switch (key) {
						case 1:
							pathname = 'firstcontol'
							break
						case 2:
							pathname = 'Rfirstcontol'
							break
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname
						}
					})
					window.open(newurl.href, '_blank')
				}
			},
			// 获取自组件传过来的值
			DataJson(data) {
				let strNode = data[0];
				let strMark = data[1];
				this.$nextTick(() => {
					this.infoList[strNode][strMark] = data[2];
				});
			},
			isshowfase() {
				this.isComShow = false;
			},
			closeCompon() {
				this.$emit("sendStatus", "param21", false);
			},
			isClose(val, val1) {
				switch (val) {
					case "Historical":
						return (this.isHshow = val1);
				}
			},
			toIpt(data, name, historyname, node) {
				this.$refs.inputVal.open(data, name, historyname, node);
			},
			toCompon(key, name, name2, name3, name4, titname) {
				this.isIndex = key;
				this.historyname = name;
				this.node = name2;
				this.Lkname = name3;
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							return (
								(this.isMshow = true),
								(this.Manualname = name),
								(this.Manualnode = name2),
								(this.ManualAname = name3),
								(this.Manualtitname = titname)
							);
						case 1:
							return (
								(this.isFshow = true),
								(this.Firstcontrolname = name),
								(this.Firstcontrolnode = name2),
								(this.Firstcontroltitname = titname)
							);
						case 2:
							return (this.isHshow = true),
							(this.chName = name4);
						case 4:
							return (
								(this.isRshow = true),
								(this.Rsfname = name),
								(this.Rsfnode = name2),
								(this.Rsftitname = titname)
							);
					}
				}, 300)

			},
		},
	};
</script>

<style lang="scss" scoped>
	.param11 {
		width: 1024px;
		height: 600px;
		background-image: url("~@/assets/images/rfl_switch.png");
		background-size: 100% 100%;

		.otherhis {
			color: #fff;
			font-size: 1vw;
			position: absolute;
			left: 530px;
			bottom: -10px;
		}

		.fl {
			float: left;
		}

		.fr {
			float: right;
		}

		.param11-header {
			height: 50px;
			//  background: #5DCEF3;
			line-height: 50px;
			text-align: center;
			color: #041A28;
			font-size: 24px;
			position: relative;
			border-radius: 5px 5px 0 0;

			.param11-header-title {
				width: 179px;
				height: 28px;
				font-family: MicrosoftYaHei;
				font-size: 24px;
				font-weight: normal;
				font-stretch: normal;
				line-height: 20px;
				letter-spacing: 0px;
				color: #0ef7ff;
			}

			.param11-header-icon {
				width: 2vw;
				height: 2vh;
				position: absolute;
				right: 1vw;
				margin-top: -5vh;
				cursor: pointer;
			}
		}

		.param11-tab {
			.param11-tab-tit {
				margin-top: 10px;
				width: 100%;
				height: 26px;
				font-family: PingFang-SC-Regular;
				line-height: 20px;
				font-weight: normal;
				font-stretch: normal;
				font-size: 24px;
				text-align: center;
				letter-spacing: 6px;
				color: #fff;
			}

			.tab-row {
				width: 992px;
				height: 569px;
				margin: 15px 15px 34px 15px;
				// border-top: 1px solid #98A4B1;
				// border-left: 1px solid #98A4B1;
				// border-bottom: 1px solid #98A4B1;

				.row1 {
					margin-top: 20px;
					margin-left: 10px;

					.row1-1 {
						margin-top: -10px;
						margin-left: 0px;
						margin-right: 5px;
						width: 75px;
						height: 91px;
						border: solid 1px #bbd6e5;
						text-align: center;
						line-height: 91px;
						position: absolute;
					}

					.row1-3 {
						margin-top: -10px;
						margin-left: 80px;
						margin-right: 5px;
						width: 65px;
						height: 91px;
						// border: solid 1px #bbd6e5;
						text-align: center;
						line-height: 91px;
						position: absolute;
					}

					.row1-3-1 {
						margin-top: 20px;
						margin-left: 80px;
						margin-right: 5px;
						width: 65px;
						height: 91px;
						// border: solid 1px #bbd6e5;
						text-align: center;
						line-height: 91px;
						position: absolute;
					}

					.row1-4 {
						margin-top: -50px;
						margin-left: -525px;
						width: 65px;
						height: 91px;
						// position: absolute;
					}

					.row1-4-1 {
						margin-top: -45px;
						margin-left: 175px;
						width: 65px;
						height: 91px;
						// position: absolute;
					}

					.row1-5 {
						margin-top: 24px;
						margin-left: 180px;
						width: 130px;
						height: 45px;
						border: solid 1px #bbd6e5;
						text-align: center;
						line-height: 45px;

					}

					.row1-6 {
						margin-top: 20px;
						margin-left: 395px;
						width: 70px;
						height: 40px;
						text-align: center;
						line-height: 40px;

					}

					.row1-7 {
						margin-top: -70px;
						margin-left: -20px;
						width: 70px;
						height: 40px;
						text-align: center;
						line-height: 40px;

					}

					.row1-8 {
						margin-top: -40px;
						margin-left: 88px;
						width: 60px;
						height: 40px;
						text-align: center;
						line-height: 40px;

					}

					.row1-9 {
						margin-top: -40px;
						margin-left: 190px;
						width: 60px;
						height: 40px;
						text-align: center;
						line-height: 40px;

					}

					.row1-10 {
						margin-top: -40px;
						margin-left: 260px;
						width: 60px;
						height: 40px;
						text-align: center;
						line-height: 40px;

					}

					.row1-11 {
						margin-top: -65px;
						margin-left: 145px;
						width: 140px;
						height: 40px;
						text-align: center;
						line-height: 40px;

					}

					.row1-12 {
						margin-top: -40px;
						margin-left: 300px;
						width: 70px;
						height: 40px;
						text-align: center;
						line-height: 40px;

					}

					.row1-13 {
						margin-top: -25px;
						margin-left: 255px;
						width: 70px;
						height: 40px;
						text-align: center;
						line-height: 40px;

					}

					.row1-14 {
						margin-top: -210px;
						margin-left: 585px;
						width: 140px;
						height: 40px;
						text-align: center;
						line-height: 40px;

					}

					.row1-15 {
						margin-top: -30px;
						margin-left: 830px;
						width: 140px;
						height: 40px;
						text-align: center;
						line-height: 40px;

					}

					.row1-16 {
						margin-top: -90px;
						margin-left: 165px;

						width: 140px;
						height: 90px;
						text-align: center;
						line-height: 40px;
						border: solid 1px #bbd6e5;

						.div1 {
							margin-top: -5px;
							margin-left: -5px;
							width: 110px;
							height: 30.3px;
							color: #8aeaff;
						}

						.div2 {
							margin-top: -30px;
							margin-left: 75px;
							width: 80px;
							height: 30.3px;
							color: #fff;
						}

						.div11 {
							margin-top: -5px;
							margin-left: -5px;
							width: 110px;
							height: 30.3px;
							color: #8aeaff;
						}

						.div22 {
							margin-top: -30px;
							margin-left: 75px;
							width: 80px;
							height: 30.3px;
							color: #fff
						}

						.div111 {
							margin-top: -5px;
							margin-left: 8px;
							width: 110px;
							height: 30.3px;
							color: #8aeaff;
						}

						.div222 {
							margin-top: -30px;
							margin-left: 70px;
							width: 90px;
							height: 30.3px;
							color: #fff
						}


					}

					.row1-17 {
						margin-top: 63px;
						margin-left: -20px;
						width: 470px;
						height: 101px;
						text-align: center;
						line-height: 100px;
						border: solid 1px #bbd6e5;
						font-family: PingFang-SC-Regular;
						font-size: 13px;
						font-weight: normal;
						font-stretch: normal;
						line-height: 25px;
						letter-spacing: -1px;
						color: #8aeaff;

						.row-17-1 {
							margin-top: -0px;
							width: 38px;
							height: 101px;
							text-align: center;
							line-height: 101px;
							border-right: solid 1px #bbd6e5;
						}

						.row-17-2 {
							margin-top: 0px;
							width: 38px;
							height: 101px;
							border-right: solid 1px #bbd6e5;
						}

						.row-17-3 {
							margin-top: 0px;
							width: 38px;
							height: 101px;
						}

					}

					.row1-19 {
						margin-top: -513px;
						margin-left: 538px;
						width: 400px;
						height: 101px;
						text-align: center;
						line-height: 100px;
						border: solid 1px #bbd6e5;
						font-family: PingFang-SC-Regular;
						font-size: 13px;
						font-weight: normal;
						font-stretch: normal;
						line-height: 25px;
						letter-spacing: -1px;
						color: #8aeaff;

						.row-19-1 {
							margin-top: -411px;
							width: 34px;
							height: 101px;
							text-align: center;
							line-height: 101px;
							border-right: solid 1px #bbd6e5;
						}

						.flex {
							margin-left: 70px;
						}

						.row-19-2 {
							margin-top: -411px;
							// margin-left: 30px;
							width: 32px;
							height: 101px;
							border-right: solid 1px #bbd6e5;
							text-align: center;
							font-size: .6vw;

							div {
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
						}

						.row-19-3 {
							margin-top: -411px;
							// margin-left: 29px;
							// width: 38px;
							height: 101px;
							text-align: center;
						}
					}

					.row1-18 {
						margin-top: -70px;
						margin-left: -190px;
						width: 140px;
						height: 40px;
						text-align: center;
						line-height: 40px;

					}

					.startbutton {
						text-align: center;
						width: 45px;
						height: 22px;
						background-color: #89b5ce;
						border-radius: 2px;
						margin-top: -120px;
						margin-left: 410px;
						font-size: 14px;
						font-weight: normal;
						font-stretch: normal;
						line-height: 20px;
						letter-spacing: 0px;
						color: #0a4c62;

					}

					.startbutton2 {
						text-align: center;
						width: 65px;
						line-height: 20px;
						background-color: #89b5ce;
						border-radius: 2px;
						margin-top: -15px;
						margin-left: 91px;
						font-family: PingFang-SC-Regular;
						font-size: 14px;
						font-weight: normal;
						font-stretch: normal;
						line-height: 20px;
						letter-spacing: 0px;
						color: #0a4c62;

					}

					.startbutton3 {
						text-align: center;
						width: 144px;
						height: 32px;
						background-color: #11f7ff;
						border-radius: 2px;
						margin-top: 210px;
						margin-left: 150px;
						font-size: 16px;
						font-weight: normal;
						font-stretch: normal;
						line-height: 32px;
						letter-spacing: 0px;
						color: #0a4c62;

					}

					.title1 {
						height: 22.75px;
						text-align: center;
						font-family: PingFang-SC-Regular;
						font-size: 14px;
						font-weight: normal;
						font-stretch: normal;
						line-height: 22.75px;
						letter-spacing: 0px;
						color: #8aeaff;
					}

					.title11 {
						height: 22.75px;
						text-align: center;
						font-family: PingFang-SC-Regular;
						font-size: 14px;
						font-weight: normal;
						font-stretch: normal;
						line-height: 22.75px;
						letter-spacing: 0px;
						color: #fff;
					}

					.title2 {
						margin-top: -20px;
						margin-left: 285px;
						height: 22.75px;
						text-align: center;
						font-family: PingFang-SC-Regular;
						font-size: 14px;
						font-weight: normal;
						font-stretch: normal;
						line-height: 22.75px;
						letter-spacing: 0px;
						color: #fff;
					}

					.buttonx {
						width: 24px;
						height: 24px;
						border: 2px solid rgb(217, 243, 145);
						text-align: center;
						line-height: 24px;
						margin-top: 85px;
						margin-left: 210px;
						color: #fff;
					}

					.buttonx1 {
						width: 24px;
						height: 24px;
						border: 2px solid rgb(217, 243, 145);
						text-align: center;
						line-height: 24px;
						margin-top: -17px;
						margin-left: 790px;
						color: #fff;
					}

					.buttonr {
						width: 24px;
						height: 24px;
						border: 2px solid rgb(217, 243, 145);
						text-align: center;
						line-height: 24px;
						margin-top: 15px;
						margin-left: 210px;
						color: #fff;
					}

					.row1-2 {
						margin-top: -20px;
						margin-left: 20px;
						width: 900px;
						height: 373px;
						// border-bottom: 1px solid #98A4B1;
						background-image: url('~@/assets/images/rts_param11.png');
						background-size: cover;
						-webkit-background-size: cover;
						position: relative;

					}





				}



			}
		}

	}
</style>
