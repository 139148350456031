<template>
    <div class="Observer drag" v-draw>
    <div class="evaluate-header">
     {{projectData.device}} _观测器模版
      <div class="evaluate-header-icon" @click="closeCompon">
        <span class="el-icon-close"></span>
      </div>
    </div>
    <div class="Observer-box">
      <div class="Observer-box-tit">
        观测器模版
      </div>
      <div class="Observer-box-flex flex">
        <div class="bserver-box-flex-left">
          <div class="left-list">
              <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">SP</div>
            <div class="list-flex1-num">
              {{infoList[node].SP}}
            </div>
          </div>
             <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">PV</div>
            <div class="list-flex1-num"
            style="color:#0de0ac"
            >
              {{infoList[node].PV}}
            </div>
          </div>
              <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">GCAV</div>
            <div class="list-flex1-num"
            style="color:#ec163e"
            >
              {{infoList[node].AV_GC}}
            </div>
          </div>
               <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">JTAV</div>
            <div class="list-flex1-num"
            style="color:#ec24ea"
            >
              {{infoList[node].AV_J}}
            </div>
          </div>
              <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">DTAV</div>
            <div class="list-flex1-num"
            style="color:#34f0f2"
            >
              {{infoList[node].AV_D}}
            </div>
          </div>
          </div>
          <div class="left-list left-list1">
               <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">TC</div>
            <div class="list-flex1-num"
            @click="toIpt(infoList[node].TC,'扰动观测器采样时间','TC',node)"
            >
              {{infoList[node].TC}}
            </div>
          </div>
              <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">TZ</div>
            <div class="list-flex1-num"
            @click="toIpt(infoList[node].TZ,'扰动观测器专家时间','TZ',node)"
            >
              {{infoList[node].TZ}}
            </div>
          </div>
               <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">JK</div>
            <div class="list-flex1-num"
            @click="toIpt(infoList[node].GC1,'扰动观测器静态作用','GC1',node)"
            >
              {{infoList[node].GC1}}
            </div>
          </div>
                <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">DK</div>
            <div class="list-flex1-num"
            @click="toIpt(infoList[node].GC2,'扰动观测器动态作用','GC2',node)"
            >
              {{infoList[node].GC2}}
            </div>
          </div>
             <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">K</div>
            <div class="list-flex1-num"
            @click="toIpt(infoList[node].GC2,'扰动观测器动态作用','GC2',node)"
            >
              {{infoList[node].GC2}}
            </div>
          </div>
              <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">OUTV</div>
            <div class="list-flex1-num"
            @click="toIpt(infoList[node].GC2,'扰动观测器动态作用','GC2',node)"
            >
              {{infoList[node].GC2}}
            </div>
          </div>
              <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">OUTL</div>
            <div class="list-flex1-num"
            @click="toIpt(infoList[node].OUTL,'扰动观测器输出下限，先控模块并联时限制','OUTL',node)"
            >
              {{infoList[node].OUTL}}
            </div>
          </div>
              <div class="list-flex1 flex align-cen">
            <div class="list-flex1-text">OUTH</div>
            <div class="list-flex1-num"
            @click="toIpt(infoList[node].OUTH,'扰动观测器输出下限，先控模块并联时限制','OUTH',node)"
            >
              {{infoList[node].OUTH}}
            </div>
          </div>
          </div>
        </div>
        <!-- 折线图 -->
        <div class="bserver-box-flex-right flex">
           <div class="right-cen-echarts">
            <div id="rts_Chart1"></div>
          </div>
          <div class="rts_Chart-right">
             <div class="lineCh" 
                >
                    <div>曲线1</div>
                    <div>Y[{{infoList[node].SP}}]</div>
                    <div>Y[{{name[0]}}]</div>
                </div>
                  <div class="lineCh lineCh2" 
                >
                    <div>曲线2</div>
                    <div>Y[{{infoList[node].PV}}]</div>
                    <div>Y[{{name[1]}}]</div>
                </div>
                  <div class="lineCh lineCh3" 
                >
                    <div>曲线3</div>
                    <div>Y[{{infoList[node].AV_GC}}]</div>
                    <div>Y[{{name[2]}}]</div>
                </div>
          </div>
        </div>
      </div>
         <div class="bot-condition-add flex" @click="downloadImpByChart">
            <!--  -->
                 <!-- <div>保存</div> -->
                 <!-- <div>载入</div> -->
             </div>
    </div>
    <inputVal ref="inputVal"></inputVal>
    </div>
</template>
<script>
import inputVal from "@/components/inputVal.vue"   //输入框组件
import { getEchars } from "@/api/index/index"
export default {
   name: "Observer",
   components: {inputVal},
    props: {
    infoList: {
      type: Object,
      default: {}, // 默认值
    },
    historyname: [String, Number], //先控名字区分
    node:[String, Number], //节点名
  },
    data: () => {
		return {
      name:[],
      mark:[],
      nodeList:[],
      xAxis:[],
      echartData:[],
      legendData:[],
      timer:null,
      myChart:''
		}
        
	},
   watch: {
    infoList: {
      handler (n, o) {
       this.infoList = n
      },
      deep: true // 深度监听父组件传过来对象变化
    },
      historyname: {
      handler (n, o) {
       this.historyname = n
      },
      deep: true // 深度监听父组件传过来对象变化
    },
      node: {
      handler (n, o) {
       this.node = n
       this.getInfo()
      },
      deep: true // 深度监听父组件传过来对象变化
    },
   },
    mounted() {
    this.getInfo()
        this.timer = setInterval(() => {
       setTimeout(this.getInfo(), 0)
        }, 1000*300)
            // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
            this.$once('hook:beforeDestroy', () => {           
                clearInterval(this.timer);                                    
            })
  },
   created(){
     this.projectData = JSON.parse(localStorage.getItem("info"))
   },
       methods:{
                  //    保存折线图片
        downloadImpByChart(){
            var url = this.myChart.getConnectedDataURL({
                    pixelRatio: 5,　　//导出的图片分辨率比率,默认是1
                    backgroundColor: 'dark',　　//图表背景色
                    excludeComponents:[　　//保存图表时忽略的工具组件,默认忽略工具栏
                    'toolbox'
                    ],
                    type:'png'　　//图片类型支持png和jpeg
                    });
                    var $a = document.createElement('a');
                    var type = 'png';
                    $a.download = this.myChart.getOption().title[0].text + '.' + type;
                    $a.target = '_blank';
                    $a.href = url;
                    // Chrome and Firefox
                    if (typeof MouseEvent === 'function') {
                    var evt = new MouseEvent('click', {
                    view: window,
                    bubbles: true,
                    cancelable: false
                    });
                    $a.dispatchEvent(evt);
                    }
                    // IE
                    else {
                    var html = ''

                    '<body style="margin:0;">'
                    '![](' + url + ')'
                    '</body>';
                    var tab = window.open();
                    tab.document.write(html);
                    }
                            },
          // 获取折线图数据
           getInfo(){
                this.echartData = []
                this.xAxis = []
           let projectData = JSON.parse(localStorage.getItem("info"))
           this.mark = ['SP','PV','AV_GC']
           this.name = [this.historyname +'_SP_WF',this.historyname +'_PV_WF',this.historyname +'_AV_GC_WF']
           this.nodeList = [this.node,this.node,this.node]
          getEchars({
                    device:projectData.device,
                    mark:this.mark,
                    name:this.name,
                    node:this.nodeList
            })
            .then(res => {
                        this.xAxis = res.data.x
                        this.echartData = res.data.series
                        this.drawLine()
            })
            .catch(err => {
              // this.$message.error('数据获取失败');
				})
    },
        // 曲线图
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("rts_Chart1"),'dark');
      this.myChart = myChart
      // 绘制图表
      myChart.setOption({
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        color:['#25495e','#0de0ac','#ec163e','#f2c334','#ec24ea','#0dd9e0','#e00d5d','#1662ec','#0de0ac'],
        legend: {
          data: this.name,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data:this.xAxis,
        },
        yAxis: {
            type: 'value',
            name: '',
            min: 0,
            position: 'left',
            axisLabel: {
                formatter: '{value}'
            }
        },
        series: this.echartData,
             dataZoom: [
        {
            //默认为滚动条型数据区域缩放组件
            show: true,
             start: 100,
            end: 0,
            // 开始位置的数值
            startValue: 100,
            // 结束位置的数值
            endValue: 0
        },
          ]
      });
    },
        //    打开输入框组件。
        toIpt(data,name,historyname,node){
            this.$refs.inputVal.open(data,name,historyname,node)
        },
        closeCompon(){
           this.$emit('sendStatus', 'Observer',false)
        },
    } 
}
</script>
<style lang="scss" scoped>
 .Observer{
     width: 1256px;
     height: 888px;
     background: #DFEDF5;
     cursor:move;
     font-family: ArialNarrow;
     border-radius: 5px;
      .evaluate-header{
         height: 50px;
         background: #5DCEF3;
         line-height: 50px;
         text-align: center;
         color: #0a4c62;
         font-size: 24px;
         position: relative;
         border-radius: 5px 5px 0 0;
         font-family: MicrosoftYaHei;
         .evaluate-header-icon{
         width: 32px;
         height: 32px;
         position: absolute;
         right: 10px;
         top: 8px;
         border-radius: 32px;
          border: 1px solid #041A28;
      color: #041A28;
         line-height: 32px;
        cursor: pointer;
     }
     }
     .Observer-box{
       margin: 10px;
     }
     .Observer-box-tit{
        width: 200px;
        height: 32px;
        background-color: #d4e3ec;
        border-radius: 2px;
        font-family: MicrosoftYaHei;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #22465b;
          text-align: center;
          line-height: 32px;
     }
     .left-list{
       padding-top: 20px;
       width: 128px;
       height: 176px;
       .list-flex1 {
         	font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #22465b;
         .list-flex1-text{
           width: 44px;
           text-align: right;
         }
         .list-flex1-num{
            width: 80px;
            height: 32px;
            background-color: rgba(158,162,164,.1);
            border-radius: 2px;
            text-align: center;
            line-height: 32px;
            margin-left:4px;
         }
       }
     }
     .left-list1{
       .list-flex1-num{
         cursor: pointer;
       }
     }
     .bserver-box-flex-right{
       #rts_Chart1{
         width: 832px;
         height: 664px;
         padding-left: 30px;
         margin-top: 10px;
       }
         .rts_Chart-right{
           margin-left: auto;
           .lineCh{
               width: 188px;
               height: 215px;
               background: rgba(158,162,164,.1);
               margin: 0 auto;
               margin-top: 10px;
               margin-left: 20px;
              
               div{
                 font-family: MicrosoftYaHei;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                letter-spacing: 0px;
                color: #22465b;
                 padding: 0 10px;
               }
               
           } 
           .lineCh2{
             div{
               color: #0de0ac;
             }
           }
            .lineCh3{
             div{
               color: #ec163e;
             }
           }
        } 
     }
       .bot-condition-add{
      position: absolute;
      bottom: 20px;
      right: 10px;
      div{
         width: 90px;
           height: 30px;
           background-color: #d4e3ec;
	       border-radius: 2px;
           line-height: 30px;
           text-align: center;
           font-size: 20px;
           color: #94acba;
	       text-shadow: 0px 1px 0px 0px #ffffff;
           margin-right: 3px; 
           cursor: pointer;
      }
  }
 }
</style>