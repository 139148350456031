<template>
	<div class="param01 drag" v-draw id="param01">
		<div class="param11-header">
			<span class="param11-header-title">{{projectData.project}}_参数01</span>
			<div class="param11-header-icon" @click="closeCompon">
				<img src="~@/assets/images/close.png" style="width: 100%; height: auto" />
			</div>
		</div>
		<div class="param01-tab">
			<div class="param01-tab-tit">煤气总管压力控制参数</div>
			<div class="tab-row">
				<div class="row1">
					<div class="row1-1">煤气压力设定</div>
					<div class="row1-1-cen flex">
						<div class="row1-1-cen-le"
							@click="toIpt(infoList.V_GONGGONG.MQPSP,'煤气压力设定','MQPSP','V_GONGGONG')">
							{{infoList.V_GONGGONG.MQPSP}}
						</div>
						<div class="row1-1-cen-ri" @click="toCompon(2,'AV','V_GONGGONG__p__MAN1','MAN1_AV_WF','煤气总管压力调节阀')"
							@dblclick="Cclick(infoList.V_GONGGONG__p__MAN1.AV,'AV', 'V_GONGGONG__p__MAN1', 'MAN1_AV_WF')">
							{{infoList.V_GONGGONG__p__MAN1.AV}}</div>
						<div class="buttonx newbuttonx"
							:style="{background:(infoList.V_GONGGONG__p__ZGXK01 && !infoList.V_GONGGONG__p__ZGXK01.TS ?'#2AFC30':'red')}"
							@click="infoList.V_GONGGONG__p__ZGXK01 ? toDetail(1,'ZGXK01','V_GONGGONG__p__ZGXK03','','煤气总管压力先控' ): ''">
							X</div>
						<div class="buttonA newbuttona"
							:style="{background:(infoList.V_GONGGONG__p__MAN1.RM != 1?'#2AFC30':'red')}"
							@click="toCompon(0,'AV ','V_GONGGONG__p__MAN1','MAN1_AV_WF','煤气总管压力调节阀')">A</div>
						<div class="row1-11">
							煤气总管压力
						</div>
						<div class="row1-11-ch" @click="toCompon(2,'PIC_01_B','V_SOUREC','PIC_01_B_WF','煤气总管压力')"
							@dblclick="Cclick(infoList.V_SOUREC.PIC_01_B,'PIC_01_B', 'V_SOUREC', 'PIC_01_B_WF')">
							{{infoList.V_SOUREC.PIC_01_B}}</div>
						<div class="row1-11 row1-12">
							空气压力设定
						</div>
						<div class="row1-12-ch"
							@click="toIpt(infoList.V_GONGGONG.KQPSP, '煤气压力设定','KQPSP','V_GONGGONG')">
							{{infoList.V_GONGGONG.KQPSP}}</div>
						<div class="row1-11 row1-13">
							单烧压力设定
						</div>
						<div class="row1-12-ch row1-13-ch"
							@click="toIpt(infoList.V_GONGGONG.KQPSP2, '单烧压力设定','KQPSP2','V_GONGGONG')">
							{{infoList.V_GONGGONG.KQPSP2}}</div>
						<div class="row1-14" @click="toCompon(2,'SP','V_GONGGONG__p__ZGXK02','ZGXK02_SP_WF','1#风机总管压力先控')"
							@dblclick="Cclick(infoList.V_GONGGONG__p__ZGXK02.SP,'SP', 'V_GONGGONG__p__ZGXK02', 'ZGXK02_SP_WF')">
							{{infoList.V_GONGGONG__p__ZGXK02.SP}}</div>
						<div class="row1-11 row1-15">
							空气总管设定
						</div>
						<div class="row1-11-ch row1-15-ch" @click="toCompon(2,'PIC_02_B','V_SOUREC','PIC_02_B_WF','助燃风总管压力')"
							@dblclick="Cclick(infoList.V_SOUREC.PIC_02_B,'PIC_02_B', 'V_SOUREC', 'PIC_02_B_WF')">
							{{infoList.V_SOUREC.PIC_02_B}}</div>
						<div class="buttonx newbuttonx1"
							:style="{background:(infoList.V_GONGGONG__p__ZGXK02 && !infoList.V_GONGGONG__p__ZGXK02.TS ?'#2AFC30':'red')}"
							@click="infoList.V_GONGGONG__p__ZGXK02 ? toDetail(1,'ZGXK02','V_GONGGONG__p__ZGXK02','','1#风机总管压力先控' ): ''">
							X</div>
						<div class="buttonA newbuttona1"
							:style="{background:(infoList.V_GONGGONG__p__MAN2.RM != 1?'#2AFC30':'red')}"
							@click="toCompon(0,'AV ','V_GONGGONG__p__MAN2','MAN2_AV_WF','1#助燃风总管压力调节阀')">A</div>
						<div class="row1-16-ch"
							@click="toIpt(infoList.V_GONGGONG.KQKWS, '支管总阀位前馈系数','KQKWS','V_GONGGONG')">
							{{infoList.V_GONGGONG.KQKWS}}</div>
						<div class="row1-11 row1-16">
							支管总阀前馈系数
						</div>
						<div class="buttonx newbuttonx2"
							:style="{background:(infoList.V_GONGGONG__p__ZGXK03 && !infoList.V_GONGGONG__p__ZGXK03.TS ?'#2AFC30':'red')}"
							@click="infoList.V_GONGGONG__p__ZGXK03 ? toDetail(1,'ZGXK03','V_GONGGONG__p__ZGXK03','','2#风机总管压力先控' ): ''">
							X</div>
						<div class="buttonA newbuttona2"
							:style="{background:(infoList.V_GONGGONG__p__MAN3.RM != 1?'#2AFC30':'red')}"
							@click="toCompon(0,'AV','V_GONGGONG__p__MAN3','MAN3_AV_WF','2#助燃风总管压力调节阀')">A</div>
						<div class="row1-11 row1-17">
							支管总阀前馈系数
						</div>
						<div class="row1-1-cen-ri row1-1-cen-ri1"
							@click="toCompon(2,'AV','V_GONGGONG__p__MAN2','MAN2_AV_WF','1#助燃风总管压力调节阀')"
							@dblclick="Cclick(infoList.V_GONGGONG__p__MAN2.AV,'AV', 'V_GONGGONG__p__MAN2', 'MAN2_AV_WF')">
							{{infoList.V_GONGGONG__p__MAN2.AV}}</div>
						<div class="row1-1-cen-ri row1-1-cen-ri2"
							@click="toCompon(2,'AV','V_GONGGONG__p__MAN3','MAN3_AV_WF','2#助燃风总管压力调节阀')"
							@dblclick="Cclick(infoList.V_GONGGONG__p__MAN3.AV,'AV', 'V_GONGGONG__p__MAN3', 'MAN3_AV_WF')">
							{{infoList.V_GONGGONG__p__MAN3.AV}}</div>
						<div class="row1-16-ch row1-17-ch"
							@click="toIpt(infoList.V_GONGGONG.TQKWS, '支管总阀位前馈系数','TQKWS','V_GONGGONG')">
							{{infoList.V_GONGGONG.TQKWS}}</div>
						<div class="row1-1-cen-ce"></div>
					</div>
					<!-- <div class="row1-2 fl">
              <div class="row1-3x" @click="toIpt(infoList.V_GONGGONG.MQPSP,'煤气压力设定','MQPSP','V_GONGGONG')">{{infoList.V_GONGGONG.MQPSP}}</div>
              <div class="buttonx fl" :style= "{background:(infoList.V_GONGGONG__p__ZGXK01 && !infoList.V_GONGGONG__p__ZGXK01.TS ?'#2AFC30':'red')}"
              @click="infoList.V_GONGGONG__p__ZGXK01 ? toDetail(1,'ZGXK01','V_GONGGONG__p__ZGXK03','','煤气总管压力先控' ): ''"
              >X</div>
              <div class="buttonA fl" :style= "{background:(infoList.V_GONGGONG__p__MAN1.RM != 1?'#2AFC30':'red')}"
              @click="toCompon(0,'AV ','V_GONGGONG__p__MAN1','MAN1_AV_WF','煤气总管压力调节阀')"
              >A</div>
              <div class="row1-4 " style="margin-top:5vh">
                <div class="title1">煤气总管压力</div>
                <div class="title2" @click="toCompon(2,'PIC_01_B','V_SOUREC','PIC_01_B_WF')"
                @dblclick="Cclick(infoList.V_SOUREC.PIC_01_B,'PIC_01_B', 'V_SOUREC', 'PIC_01_B_WF')"
                >{{infoList.V_SOUREC.PIC_01_B}}</div>
              </div>
              <div class="row1-5 ">煤气压力设定</div>
              <div class="row1-3x" style="margin-left:-80px" @click="toIpt(infoList.V_GONGGONG.KQPSP, '煤气压力设定','KQPSP','V_GONGGONG')">{{infoList.V_GONGGONG.KQPSP}}</div>
              <div class="buttonx fl" style="margin-left:450px" :style= "{background:(infoList.V_GONGGONG__p__ZGXK02 && !infoList.V_GONGGONG__p__ZGXK02.TS ?'#2AFC30':'red')}"
             @click="infoList.V_GONGGONG__p__ZGXK02 ? toDetail(1,'ZGXK02','V_GONGGONG__p__ZGXK02','','1#风机总管压力先控' ): ''"
              >X</div>
              <div class="buttonA fl"  :style= "{background:(infoList.V_GONGGONG__p__MAN2.RM != 1?'#2AFC30':'red')}"
              @click="toCompon(0,'AV ','V_GONGGONG__p__MAN2','MAN2_AV_WF','1#助燃风总管压力调节阀')"
              >A</div>
              <div class="row1-3 fl"  style="margin-left:700px; margin-top:-25px;" 
              @click="toCompon(2,'AV','V_GONGGONG__p__MAN2','MAN2_AV_WF')"
               @dblclick="Cclick(infoList.V_GONGGONG__p__MAN2.AV,'AV', 'V_GONGGONG__p__MAN2', 'MAN2_AV_WF')"
              >{{infoList.V_GONGGONG__p__MAN2.AV}}</div>
              <div class="row1-5  " style="margin-top:140px">单烧压力设定</div>
              <div class="row1-3x fl" style="margin-left:-80px" @click="toIpt(infoList.V_GONGGONG.KQPSP2, '单烧压力设定','KQPSP2','V_GONGGONG')">{{infoList.V_GONGGONG.KQPSP2}}</div>
              <div class="buttonx fl" style="margin-left:450px;margin-top:3px" :style= "{background:(infoList.V_GONGGONG__p__ZGXK03 && !infoList.V_GONGGONG__p__ZGXK03.TS ?'#2AFC30':'red')}"
               @click="infoList.V_GONGGONG__p__ZGXK03 ? toDetail(1,'ZGXK03','V_GONGGONG__p__ZGXK03','','2#风机总管压力先控' ): ''"
              >X</div>
              <div class="buttonA fl" :style= "{background:(infoList.V_GONGGONG__p__MAN3.RM != 1?'#2AFC30':'red')}"
               @click="toCompon(0,'AV','V_GONGGONG__p__MAN3','MAN3_AV_WF','2#助燃风总管压力调节阀')"
              >A</div>
              <div class="row1-3 fl"  style="margin-left:700px; margin-top:-25px;" @click="toCompon(2,'AV','V_GONGGONG__p__MAN3','MAN3_AV_WF')"
               @dblclick="Cclick(infoList.V_GONGGONG__p__MAN3.AV,'AV', 'V_GONGGONG__p__MAN3', 'MAN3_AV_WF')"
              >{{infoList.V_GONGGONG__p__MAN3.AV}}</div>
              <div class="row1-3 fl"  style="margin-left:130px; margin-top:-125px;" @click="toCompon(2,'SP','V_GONGGONG__p__ZGXK02','ZGXK02_SP_WF')"
              @dblclick="Cclick(infoList.V_GONGGONG__p__ZGXK02.SP,'SP', 'V_GONGGONG__p__ZGXK02', 'ZGXK02_SP_WF')"
              >{{infoList.V_GONGGONG__p__ZGXK02.SP}}</div>
              <div class="row1-4">
                <div class="title1" style="margin-left:115px; margin-top:-170px;">空气总管压力</div>
                <div class="title2" style="margin-left:150px;" @click="toCompon(2,'PIC_02_B','V_SOUREC','PIC_02_B_WF')"
                 @dblclick="Cclick(infoList.V_SOUREC.PIC_02_B,'PIC_02_B', 'V_SOUREC', 'PIC_02_B_WF')"
                >{{infoList.V_SOUREC.PIC_02_B}}</div>
              </div>

              <div class="title1" style="margin-left:180px; margin-top:-170px;"></div>
              <div class="row1-3x" style="margin-left:560px; margin-top:110px;"  @click="toIpt(infoList.V_GONGGONG.KQKWS, '支管总阀位前馈系数','KQKWS','V_GONGGONG')">{{infoList.V_GONGGONG.KQKWS}}</div>
              <div class="row1-5  " style="margin-top:10px;margin-left:510px;width:200px">支管总阀位前馈系数</div>
              <div class="row1-3x" style="margin-left:570px; margin-top:140px;"  @click="toIpt(infoList.V_GONGGONG.TQKWS, '支管总阀位前馈系数','TQKWS','V_GONGGONG')">{{infoList.V_GONGGONG.TQKWS}}</div>
              <div class="row1-5  " style="margin-top:10px;margin-left:510px;width:200px">支管总阀位前馈系数</div>
            </div> -->

					<!-- <div class="row1-3 fl" @click="toCompon(2,'AV','V_GONGGONG__p__MAN1','MAN1_AV_WF')"
             @dblclick="Cclick(infoList.V_GONGGONG__p__MAN1.AV,'AV', 'V_GONGGONG__p__MAN1', 'MAN1_AV_WF')"
            >{{infoList.V_GONGGONG__p__MAN1.AV}}</div> -->
				</div>


			</div>
		</div>
		<inputVal ref="inputVal" @getTreeData='DataJson(arguments)'></inputVal>
		 <Historical v-if="isHshow" @sendStatus='isHshow=false' :historyname='historyname' :node='node' :Lkname='Lkname'
			:chName="chName" :infoList='infoList'></Historical>
		<Firstcontrol :infoList='infoList' :isIndex='isIndex' :historyname='Firstcontrolname'
			:titname='Firstcontroltitname' :node='Firstcontrolnode' v-if="isFshow" @sendStatus='isFshow=false'>
		</Firstcontrol>
		<Manual :key="isIndex" v-if="isMshow" @sendStatus='isMshow=false' :historyname='Manualname' :node='Manualnode'
			:Lkname='ManualAname' :titname='Manualtitname' :infoList='infoList'></Manual>
	</div>
</template>
<script>
	import inputVal from "./inputVal" //输入框组件
	import Historical from "@/components/Historical.vue" //历史趋势
	import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
	import Manual from "@/components/Manual.vue"; //手操器组件
	export default {
		name: "Param01",
		components: {
			inputVal,
			Historical,
			Firstcontrol,
			Manual
		},
		props: {
			infoList: {
				type: Object,
				default: {} // 默认值
			}
		},

		data: () => {
			return {
				chName: '',
				isComShow: false,
				isHshow: false,
				isClose: false,
				isMshow: false,
				historyname: '',
				node: '',
				Lkname: '',
				isIndex: '',
				Firstcontrolname: '',
				Firstcontroltitname: '',
				Firstcontrolnode: '',
				isFshow: false,
				Manualname: '',
				Manualnode: '',
				ManualAname: '',
				Manualtitname: '',
				spotArr: [],
				grouptime: null
			}

		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
		},
		methods: {
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {

						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			DataJson(data) {
				let strNode = data[0]
				let strMark = data[1]
				this.$nextTick(() => {
					this.infoList[strNode][strMark] = data[2]
				});
			},
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				const newurl = this.$router.resolve({
					name: 'firstcontol',
					query: {
						Firstcontrolname: name,
						Firstcontrolnode: nodename,
						Firstcontroltitname: titname
					}
				})
				localStorage.setItem("infoList", JSON.stringify(this.infoList))
				window.open(newurl.href, '_blank')
			},
			toIpt(data, name, historyname, node) {
				this.$refs.inputVal.open(data, name, historyname, node)
			},
			closeCompon() {
				this.$emit('sendStatus', 'Param01', false)
			},
			isClose1() {
				this.isHshow = false
			},
			toCompon(key, name, name2, name3, name4, titname) {

				this.isIndex = key
				this.historyname = name
				this.node = name2
				this.Lkname = name3
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							return this.isMshow = true, this.Manualname = name, this.Manualnode = name2, this
								.ManualAname = name3, this.Manualtitname = titname
						case 1:
							return this.isFshow = true, this.Firstcontrolname = name, this.Firstcontrolnode =
								name2, this.Firstcontroltitname = titname
						case 2:
							return this.isHshow = true, this.chName = name4
					}
				}, 300)
			},
		}
	}
</script>
<style lang="scss" scoped>
	.param01 {
		width: 1024px;
		height: 67vh;
		background-image: url("~@/assets/images/rfl_switch.png");
		background-size: 100% 100%;

		.fl {
			float: left;
		}

		.fr {
			float: right;
		}

		.param11-header {
			height: 50px;
			//  background: #5DCEF3;
			line-height: 50px;
			text-align: center;
			color: #041a28;
			font-size: 24px;
			position: relative;
			border-radius: 5px 5px 0 0;

			.param11-header-title {
				width: 179px;
				height: 28px;
				font-family: MicrosoftYaHei;
				font-size: 24px;
				font-weight: normal;
				font-stretch: normal;
				line-height: 20px;
				letter-spacing: 0px;
				color: #0ef7ff;
			}

			.param11-header-icon {
				width: 2vw;
				height: 2vh;
				position: absolute;
				right: 1vw;
				margin-top: -5vh;
				cursor: pointer;
			}
		}

		.param01-header {
			height: 50px;
			background: #5DCEF3;
			line-height: 50px;
			text-align: center;
			color: #041A28;
			font-size: 24px;
			position: relative;
			border-radius: 5px 5px 0 0;

			.param01-header-title {
				width: 179px;
				height: 28px;
				font-family: MicrosoftYaHei;
				font-size: 24px;
				font-weight: normal;
				font-stretch: normal;
				line-height: 20px;
				letter-spacing: 0px;
				color: #fff;
			}

			.param01-header-icon {
				width: 32px;
				height: 32px;
				position: absolute;
				right: 15px;
				top: 5px;
				border-radius: 32px;
				border: 1px solid #041A28;
				color: #041A28;
				line-height: 32px;
				cursor: pointer;
			}
		}

		.param01-tab {

			.param01-tab-tit {
				margin-top: 2vh;
				width: 100%;
				height: 3vh;
				font-family: PingFang-SC-Regular;
				line-height: 3vh;
				font-weight: normal;
				font-stretch: normal;
				font-size: 3vh;
				text-align: center;
				letter-spacing: 6px;
				color: #d5fffe;
			}

			.tab-row {
				width: 100%;
				height: 55vh;

				.row1 {
					.row1-4 {
						margin-top: 60px;
						margin-left: 190px;
						width: 400px;
						height: 13px;
						font-family: PingFang-SC-Regular;
						font-size: 16px;
						font-weight: normal;
						font-stretch: normal;
						line-height: 20px;
						letter-spacing: 0px;
						color: #fff;

						.title1 {
							width: 140px;
							height: 19px;
							font-family: PingFang-SC-Regular;
							font-size: 20px;
							font-weight: normal;
							font-stretch: normal;
							line-height: 20px;
							letter-spacing: 0px;
							color: #fff;
						}

						.title2 {
							margin-top: 10px;
							margin-left: 40px;
							width: 140px;
							height: 19px;
							font-family: PingFang-SC-Regular;
							font-size: 16px;
							font-weight: normal;
							font-stretch: normal;
							line-height: 20px;
							letter-spacing: 0px;

						}

					}

					.row1-1 {
						font-family: PingFang-SC-Regular;
						font-size: 1vw;
						font-weight: normal;
						font-stretch: normal;
						line-height: 1vw;
						letter-spacing: 0px;
						color: #d5fffe;
						width: 40vw;
						margin: 0 auto;
						margin-top: 5vh;
					}

					.row1-1-cen {
						width: 40vw;
						margin: 0 auto;
						margin-top: 1vh;
						position: relative;

						.row1-1-cen-le {
							width: 5vw;
							text-align: center;
							font-size: 1vw;
							color: #00ffb4;
							cursor: pointer;
						}

						.row1-1-cen-ri {
							position: absolute;
							font-size: 1vw;
							color: #d5fffe;
							cursor: pointer;
							right: -6vw;
							width: 5vw;
							top: 0.5vh;
						}

						.row1-1-cen-ri1 {
							top: 15.5vh;
						}

						.row1-1-cen-ri2 {
							top: 32vh;
						}

						.row1-14 {
							position: absolute;
							font-size: 1vw;
							color: #d5fffe;
							cursor: pointer;
							left: 10.5vw;
							width: 5vw;
							top: 23.5vh;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							text-align: center;
						}

						.row1-11 {
							font-family: PingFang-SC-Regular;
							font-size: 1vw;
							font-weight: normal;
							font-stretch: normal;
							line-height: 1vw;
							letter-spacing: 0px;
							color: #d5fffe;
							position: absolute;
							top: 8vh;
							left: 15vw;
						}

						.row1-12 {
							top: 14vh;
							left: -2vw;
						}

						.row1-13 {
							left: -2vw;
							top: 29vh;
						}

						.row1-15 {
							left: 20.5vw;
							top: 23.5vh;
						}

						.row1-16 {
							left: 30vw;
							top: 23vh;
						}

						.row1-17 {
							left: 30vw;
							top: 40vh;
						}

						.row1-11-ch {
							position: absolute;
							font-size: 1vw;
							color: #d5fffe;
							cursor: pointer;
							top: 10vh;
							left: 15.5vw;
							width: 5vw;
							text-align: center;
						}

						.row1-12-ch {
							color: #00ffb4;
							cursor: pointer;
							font-size: 1vw;
							position: absolute;
							top: 16vh;
							left: -2vw;
							width: 5vw;
							text-align: center;
						}

						.row1-13-ch {
							left: -2vw;
							top: 32vh;
						}

						.row1-15-ch {
							left: 21vw;
							top: 25vh;
							width: 5vw;
							text-align: center;
							cursor: pointer;
							font-size: 1vw;
						}

						.row1-16-ch {
							color: #00ffb4;
							cursor: pointer;
							font-size: 1vw;
							position: absolute;
							top: 19vh;
							right: 5vw;
							width: 5vw;
							text-align: center;

						}

						.row1-17-ch {
							top: 35vh;
							right: 5vw;
						}

						.row1-1-cen-ce {
							width: 35vw;
							height: 35vh;
							background: url('~@/assets/images/rts_param01.png') no-repeat 0 0;
							background-size: 100% 100%;

						}
					}

					.row1-5 {

						margin-left: -110px;
						width: 130px;
						height: 19px;
						font-family: PingFang-SC-Regular;
						font-size: 20px;
						font-weight: normal;
						font-stretch: normal;
						line-height: 20px;
						letter-spacing: 0px;
						color: #fff;
					}

					.row1-2 {
						width: 675px;
						height: 40vh;
						// border-bottom: 1px solid #98A4B1;
						background-image: url('~@/assets/images/rts_param01.png');
						background-size: 100% 100%;
						-webkit-background-size: 100% 100%;

					}

					.row1-3 {
						margin-top: 10px;
						margin-left: 10px;
						width: 43px;
						height: 13px;
						font-family: PingFang-SC-Regular;
						font-size: 16px;
						font-weight: normal;
						font-stretch: normal;
						line-height: 20px;
						letter-spacing: 0px;
						color: #fff;

					}

					.row1-3x {
						background-color: rgba(102, 136, 152, .2);
						box-shadow: 0px 1px 0px 0px rgba(21, 56, 76, .2);
						border-radius: 2px;
						margin-top: 10px;
						margin-left: 8px;
						text-align: center;
						width: 60px;
						height: 25px;
						line-height: 25px;
						font-family: PingFang-SC-Regular;
						font-size: 16px;
						font-weight: normal;
						font-stretch: normal;
						letter-spacing: 0px;
						color: #1662ec;

					}

					.row1-2-1 {
						margin-top: 10px;
						text-align: center;
						width: 66px;
						height: 24px;
						// background-color: #668898;
						// background-color: #b4d1df;
						// box-shadow: 0px 1px 0px 0px
						// #15384c;
						border-radius: 2px;
						opacity: 0.2;

					}

					.title {
						//  	width: 58px;
						// height: 13px;
						font-family: PingFang-SC-Regular;
						font-size: 18px;
						font-weight: normal;
						font-stretch: normal;
						line-height: 20px;
						letter-spacing: 0px;
						// color: #0a41ad;
					}

					.buttonx {
						width: 1vw;
						height: 2vh;
						border: 2px solid rgb(217, 243, 145);
						text-align: center;
						line-height: 2vh;
						cursor: pointer;
					}

					.newbuttonx {
						position: absolute;
						right: 12vw;
						top: .5vh;
					}

					.newbuttonx1 {
						position: absolute;
						right: 12vw;
						top: 16vh;
					}

					.newbuttonx2 {
						position: absolute;
						right: 12vw;
						top: 32vh;
					}

					.newbuttona {
						position: absolute;
						right: 7vw;
						top: .5vh;
					}

					.newbuttona1 {
						position: absolute;
						right: 7vw;
						top: 16vh;
					}

					.newbuttona2 {
						position: absolute;
						right: 7vw;
						top: 32vh;
					}

					.buttonA {
						width: 1vw;
						height: 2vh;
						border: 2px solid rgb(217, 243, 145);
						text-align: center;
						line-height: 2vh;
						cursor: pointer;
					}




				}



			}
		}

	}
</style>
