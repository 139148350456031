<template>
	<div class="param drag" v-draw id="param">
		<div class="param-header">
			<span class="param-header-title">{{projectData.project}}_公共参数</span>
			<div class="param-header-icon" @click="closeCompon">
				<img src="~@/assets/images/close.png" style="width: 100%; height: auto" />
			</div>
		</div>
		<div class="param-tab">
			<div class="param-tab-tit">公共参数</div>
			<div class="tab-row flex">
				<div class="row1">
					<div class="row1-1">
						<div class="row1-1tit">
							1#炉流量软测量模型参数
						</div>
						<div class="row1-2">
							<div>
								<div class="tab1l fl">助燃风软测量</div>
								<div class="tab1r fr">煤气软测量</div>
							</div>
							<div>
								<div class="tab2-1 fl">阀位上限</div>
								<div class="tab2-2 fl"
									@click="toIpt(infoList.V_RCL1.PZKQ_B1, '阀位上限','PZKQ_B1','V_RCL1')">
									{{infoList.V_RCL1.PZKQ_B1}}</div>
								<div class="tab2-3 fl">阀位上限</div>
								<div class="tab2-4 fl"
									@click="toIpt(infoList.V_RCL1.PZMQ_B1, '阀位上限','PZMQ_B1','V_RCL1')">
									{{infoList.V_RCL1.PZMQ_B1}}</div>
							</div>
							<div>
								<div class="tab2-1 fl">压力系数</div>
								<div class="tab2-2 fl"
									@click="toIpt(infoList.V_RCL1.KQ02_B1, '压力系数','KQ02_B1','V_RCL1')">
									{{infoList.V_RCL1.KQ02_B1}}</div>
								<div class="tab2-3 fl">压力系数</div>
								<div class="tab2-4 fl"
									@click="toIpt(infoList.V_RCL1.KM01_B1, '压力系数','KM01_B1','V_RCL1')">
									{{infoList.V_RCL1.KM01_B1}}</div>
							</div>
							<div>
								<div class="tab2-1 fl">阀位系数</div>
								<div class="tab2-2 fl"
									@click="toIpt(infoList.V_RCL1.MCY_01_B1, '阀位系数','MCY_01_B1','V_RCL1')">
									{{infoList.V_RCL1.MCY_01_B1}}</div>
								<div class="tab2-3 fl">阀位系数</div>
								<div class="tab2-4 fl"
									@click="toIpt(infoList.V_RCL1.FM02_B1, '阀位系数','FM02_B1','V_RCL1')">
									{{infoList.V_RCL1.FM02_B1}}</div>
							</div>

							<div>
								<div class="tab2-1 fl">空气偏置</div>
								<div class="tab2-2 fl"
									@click="toIpt(infoList.V_RCL1.QCY_01_B1, '空气偏置','QCY_01_B1','V_RCL1')">
									{{infoList.V_RCL1.QCY_01_B1}}</div>
								<div class="tab2-3 fl">空气偏置</div>
								<div class="tab2-4 fl"
									@click="toIpt(infoList.V_RCL1.FK02_B1, '空气偏置','FK02_B1','V_RCL1')">
									{{infoList.V_RCL1.FK02_B1}}</div>
							</div>

							<div>
								<div class="tab2-1 fl">软测输出</div>
								<div class="tab2-2-1 fl" @click="toCompon(2,'FK01_B1','V_RCL1','FK01_B1_WF','1#计算空气流量1')"
									@dblclick="Cclick(infoList.V_RCL1.FK01_B1,'FK01_B1', 'V_RCL1', 'FK01_B1_WF')">
									{{infoList.V_RCL1.FK01_B1}}</div>
								<div class="tab2-3 fl">软测输出</div>
								<div class="tab2-4-1 fl" @click="toCompon(2,'FM01_B1','V_RCL1','FM01_B1_WF','1#计算煤气流量1')"
									@dblclick="Cclick(infoList.V_RCL1.FM01_B1,'FM01_B1', 'V_RCL1', 'FM01_B1_WF')">
									{{infoList.V_RCL1.FM01_B1}}</div>
							</div>

							<div style="display:inline-block">
								<div class="tab2-1 fl">PLC输出</div>
								<div class="tab2-2-1 fl" @click="toCompon(2,'FTB_21_B1','V_SOUREC','FTB_21_B1_WF','1#热风炉助燃风流量1')"
									@dblclick="Cclick(infoList.V_SOUREC.FTB_21_B1,'FTB_21_B1', 'V_SOUREC', 'FTB_21_B1_WF')">
									{{infoList.V_SOUREC.FTB_21_B1}}</div>
								<div class="tab2-3 fl">PLC输出</div>
								<div class="tab2-4-1 fl" @click="toCompon(2,'FTB_11_B1','V_SOUREC','FTB_11_B1_WF','1#热风炉煤气流量1')"
									@dblclick="Cclick(infoList.V_SOUREC.FTB_11_B1,'FTB_11_B1', 'V_SOUREC', 'FTB_11_B1_WF')">
									{{infoList.V_SOUREC.FTB_11_B1}}</div>
							</div>
						</div>
						<div class="row1-1tit">
							3#炉流量软测量模型参数
						</div>
						<div class="row1-2">
							<div>
								<div class="tab1l fl">助燃风软测量</div>
								<div class="tab1r fr">煤气软测量</div>
							</div>
							<div>
								<div class="tab2-1 fl">阀位上限</div>
								<div class="tab2-2 fl"
									@click="toIpt(infoList.V_RCL3.PZKQ_B3, '阀位上限','PZKQ_B3','V_RCL3')">
									{{infoList.V_RCL3.PZKQ_B3}}</div>
								<div class="tab2-3 fl">阀位上限</div>
								<div class="tab2-4 fl"
									@click="toIpt(infoList.V_RCL3.PZMQ_B3, '阀位上限','PZMQ_B3','V_RCL3')">
									{{infoList.V_RCL3.PZMQ_B3}}</div>
							</div>
							<div>
								<div class="tab2-1 fl">压力系数</div>
								<div class="tab2-2 fl"
									@click="toIpt(infoList.V_RCL3.KQ02_B3, '压力系数','KQ02_B3','V_RCL3')">
									{{infoList.V_RCL3.KQ02_B3}}</div>
								<div class="tab2-3 fl">压力系数</div>
								<div class="tab2-4 fl"
									@click="toIpt(infoList.V_RCL3.KM01_B3, '压力系数','KM01_B3','V_RCL3')">
									{{infoList.V_RCL3.KM01_B3}}</div>
							</div>
							<div>
								<div class="tab2-1 fl">阀位系数</div>
								<div class="tab2-2 fl"
									@click="toIpt(infoList.V_RCL3.MCY_01_B3, '阀位系数','MCY_01_B3','V_RCL3')">
									{{infoList.V_RCL3.MCY_01_B3}}</div>
								<div class="tab2-3 fl">阀位系数</div>
								<div class="tab2-4 fl"
									@click="toIpt(infoList.V_RCL3.FM02_B3, '阀位系数','FM02_B3','V_RCL3')">
									{{infoList.V_RCL3.FM02_B3}}</div>
							</div>

							<div>
								<div class="tab2-1 fl">空气偏置</div>
								<div class="tab2-2 fl"
									@click="toIpt(infoList.V_RCL3.QCY_01_B3, '空气偏置','QCY_01_B3','V_RCL3')">
									{{infoList.V_RCL3.QCY_01_B3}}</div>
								<div class="tab2-3 fl">空气偏置</div>
								<div class="tab2-4 fl"
									@click="toIpt(infoList.V_RCL3.FK02_B3, '空气偏置','FK02_B3','V_RCL3')">
									{{infoList.V_RCL3.FK02_B3}}</div>
							</div>

							<div>
								<div class="tab2-1 fl">软测输出</div>
								<div class="tab2-2-1 fl" @click="toCompon(2,'FK01_B3','V_RCL3','FK01_B3_WF','3#计算空气流量1')"
									@dblclick="Cclick(infoList.V_RCL3.FK01_B3,'FK01_B3', 'V_RCL3', 'FK01_B3_WF')">
									{{infoList.V_RCL3.FK01_B3}}</div>
								<div class="tab2-3 fl">软测输出</div>
								<div class="tab2-4-1 fl" @click="toCompon(2,'FM01_B3','V_RCL3','FM01_B3_WF','3#计算煤气流量1')"
									@dblclick="Cclick(infoList.V_RCL3.FM01_B3,'FM01_B3', 'V_RCL3', 'FM01_B3_WF')">
									{{infoList.V_RCL3.FM01_B3}}</div>
							</div>

							<div style="display:inline-block">
								<div class="tab2-1 fl">PLC输出</div>
								<div class="tab2-2-1 fl" @click="toCompon(2,'FTB_21_B3','V_SOUREC','FTB_21_B3_WF','3#热风炉助燃风流量1')"
									@dblclick="Cclick(infoList.V_SOUREC.FTB_21_B3,'FTB_21_B3', 'V_SOUREC', 'FTB_21_B3_WF')">
									{{infoList.V_SOUREC.FTB_21_B3}}</div>
								<div class="tab2-3 fl">PLC输出</div>
								<div class="tab2-4-1 fl" @click="toCompon(2,'FTB_11_B3','V_SOUREC','FTB_11_B3_WF','3#热风炉煤气流量1')"
									@dblclick="Cclick(infoList.V_SOUREC.FTB_11_B3,'FTB_11_B3', 'V_SOUREC', 'FTB_11_B3_WF')">
									{{infoList.V_SOUREC.FTB_11_B3}}</div>
							</div>


						</div>
					</div>
				</div>
				<div class="row1">
					<div class="row1-1">
						<div class="row1-1tit">
							2#炉流量软测量模型参数
						</div>
						<div class="row1-2">
							<div>
								<div class="tab1l fl">助燃风软测量</div>
								<div class="tab1r fr">煤气软测量</div>
							</div>
							<div>
								<div class="tab2-1 fl">阀位上限</div>
								<div class="tab2-2 fl"
									@click="toIpt(infoList.V_RCL2.PZKQ_B2, '阀位上限','PZKQ_B2','V_RCL2')">
									{{infoList.V_RCL2.PZKQ_B2}}</div>
								<div class="tab2-3 fl">阀位上限</div>
								<div class="tab2-4 fl"
									@click="toIpt(infoList.V_RCL2.PZMQ_B2, '阀位上限','PZMQ_B2','V_RCL2')">
									{{infoList.V_RCL2.PZMQ_B2}}</div>
							</div>
							<div>
								<div class="tab2-1 fl">压力系数</div>
								<div class="tab2-2 fl"
									@click="toIpt(infoList.V_RCL2.KQ02_B2, '压力系数','KQ02_B2','V_RCL2')">
									{{infoList.V_RCL2.KQ02_B2}}</div>
								<div class="tab2-3 fl">压力系数</div>
								<div class="tab2-4 fl"
									@click="toIpt(infoList.V_RCL2.KM01_B2, '压力系数','KM01_B2','V_RCL2')">
									{{infoList.V_RCL2.KM01_B2}}</div>
							</div>
							<div>
								<div class="tab2-1 fl">阀位系数</div>
								<div class="tab2-2 fl"
									@click="toIpt(infoList.V_RCL2.MCY_01_B2, '阀位系数','MCY_01_B2','V_RCL2')">
									{{infoList.V_RCL2.MCY_01_B2}}</div>
								<div class="tab2-3 fl">阀位系数</div>
								<div class="tab2-4 fl"
									@click="toIpt(infoList.V_RCL2.FM02_B2, '阀位系数','FM02_B2','V_RCL2')">
									{{infoList.V_RCL2.FM02_B2}}</div>
							</div>

							<div>
								<div class="tab2-1 fl">空气偏置</div>
								<div class="tab2-2 fl"
									@click="toIpt(infoList.V_RCL2.QCY_01_B2, '空气偏置','QCY_01_B2','V_RCL2')">
									{{infoList.V_RCL2.QCY_01_B2}}</div>
								<div class="tab2-3 fl">空气偏置</div>
								<div class="tab2-4 fl"
									@click="toIpt(infoList.V_RCL2.FK02_B2, '空气偏置','FK02_B2','V_RCL2')">
									{{infoList.V_RCL2.FK02_B2}}</div>
							</div>

							<div>
								<div class="tab2-1 fl">软测输出</div>
								<div class="tab2-2-1 fl" @click="toCompon(2,'FK01_B2','V_RCL2','FK01_B2_WF','2#计算空气流量1')"
									@dblclick="Cclick(infoList.V_RCL2.FK01_B2,'FK01_B2', 'V_RCL2', 'FK01_B2_WF')">
									{{infoList.V_RCL2.FK01_B2}}</div>
								<div class="tab2-3 fl">软测输出</div>
								<div class="tab2-4-1 fl" @click="toCompon(2,'FM01_B2','V_RCL2','FM01_B2_WF','2#计算煤气流量1')"
									@dblclick="Cclick(infoList.V_RCL2.FM01_B2,'FM01_B2', 'V_RCL2', 'FM01_B2_WF')">
									{{infoList.V_RCL2.FM01_B2}}</div>
							</div>

							<div style="display:inline-block">
								<div class="tab2-1 fl">PLC输出</div>
								<div class="tab2-2-1 fl" @click="toCompon(2,'FTB_21_B2','V_SOUREC','FTB_21_B2_WF','2#热风炉助燃风流量1')"
									@dblclick="Cclick(infoList.V_SOUREC.FTB_21_B2,'FTB_21_B2', 'V_SOUREC', 'FTB_21_B2_WF')">
									{{infoList.V_SOUREC.FTB_21_B2}}</div>
								<div class="tab2-3 fl">PLC输出</div>
								<div class="tab2-4-1 fl" @click="toCompon(2,'FTB_11_B2','V_SOUREC','FTB_11_B2_WF','2#热风炉煤气流量1')"
									@dblclick="Cclick(infoList.V_SOUREC.FTB_11_B2,'FTB_11_B2', 'V_SOUREC', 'FTB_11_B2_WF')">
									{{infoList.V_SOUREC.FTB_11_B2}}</div>
							</div>

						</div>
						<div class="row1-1tit">
							4#炉流量软测量模型参数
						</div>
						<div class="row1-2">
							<div>
								<div class="tab1l fl">助燃风软测量</div>
								<div class="tab1r fr">煤气软测量</div>
							</div>
							<div>
								<div class="tab2-1 fl">阀位上限</div>
								<div class="tab2-2 fl"
									@click="toIpt(infoList.V_RCL4.PZKQ_B4, '阀位上限','PZKQ_B4','V_RCL4')">
									{{infoList.V_RCL4.PZKQ_B4}}</div>
								<div class="tab2-3 fl">阀位上限</div>
								<div class="tab2-4 fl"
									@click="toIpt(infoList.V_RCL4.PZMQ_B4, '阀位上限','PZMQ_B4','V_RCL4')">
									{{infoList.V_RCL4.PZMQ_B4}}</div>
							</div>
							<div>
								<div class="tab2-1 fl">压力系数</div>
								<div class="tab2-2 fl"
									@click="toIpt(infoList.V_RCL4.KQ02_B4, '压力系数','KQ02_B4','V_RCL4')">
									{{infoList.V_RCL4.KQ02_B4}}</div>
								<div class="tab2-3 fl">压力系数</div>
								<div class="tab2-4 fl"
									@click="toIpt(infoList.V_RCL4.KM01_B4, '压力系数','KM01_B4','V_RCL4')">
									{{infoList.V_RCL4.KM01_B4}}</div>
							</div>
							<div>
								<div class="tab2-1 fl">阀位系数</div>
								<div class="tab2-2 fl"
									@click="toIpt(infoList.V_RCL4.MCY_01_B4, '阀位系数','MCY_01_B4','V_RCL4')">
									{{infoList.V_RCL4.MCY_01_B4}}</div>
								<div class="tab2-3 fl">阀位系数</div>
								<div class="tab2-4 fl"
									@click="toIpt(infoList.V_RCL4.FM02_B4, '阀位系数','FM02_B4','V_RCL4')">
									{{infoList.V_RCL4.FM02_B4}}</div>
							</div>

							<div>
								<div class="tab2-1 fl">空气偏置</div>
								<div class="tab2-2 fl"
									@click="toIpt(infoList.V_RCL4.QCY_01_B4, '空气偏置','QCY_01_B4','V_RCL4')">
									{{infoList.V_RCL4.QCY_01_B4}}</div>
								<div class="tab2-3 fl">空气偏置</div>
								<div class="tab2-4 fl"
									@click="toIpt(infoList.V_RCL4.FK02_B4, '空气偏置','FK02_B4','V_RCL4')">
									{{infoList.V_RCL4.FK02_B4}}</div>
							</div>

							<div>
								<div class="tab2-1 fl">软测输出</div>
								<div class="tab2-2-1 fl" @click="toCompon(2,'FK01_B4','V_RCL4','FK01_B4_WF','4#计算空气流量1')"
									@dblclick="Cclick(infoList.V_RCL4.FK01_B4,'FK01_B4', 'V_RCL4', 'FK01_B4_WF')">
									{{infoList.V_RCL4.FK01_B4}}</div>
								<div class="tab2-3 fl">软测输出</div>
								<div class="tab2-4-1 fl" @click="toCompon(2,'FM01_B4','V_RCL4','FM01_B4_WF','4#计算煤气流量1')"
									@dblclick="Cclick(infoList.V_RCL4.FM01_B4,'FM01_B4', 'V_RCL4', 'FM01_B4_WF')">
									{{infoList.V_RCL4.FM01_B4}}</div>
							</div>

							<div style="display:inline-block">
								<div class="tab2-1 fl">PLC输出</div>
								<div class="tab2-2-1 fl" @click="toCompon(2,'FTB_21_B4','V_SOUREC','FTB_21_B4_WF','4#热风炉助燃风流量1')"
									@dblclick="Cclick(infoList.V_SOUREC.FTB_21_B4,'FTB_21_B4', 'V_SOUREC', 'FTB_21_B4_WF')">
									{{infoList.V_SOUREC.FTB_21_B4}}</div>
								<div class="tab2-3 fl">PLC输出</div>
								<div class="tab2-4-1 fl" @click="toCompon(2,'FTB_11_B4','V_SOUREC','FTB_11_B4_WF','4#热风炉煤气流量1')"
									@dblclick="Cclick(infoList.V_SOUREC.FTB_11_B4,'FTB_11_B4', 'V_SOUREC', 'FTB_11_B4_WF')">
									{{infoList.V_SOUREC.FTB_11_B4}}</div>
							</div>

						</div>
					</div>
				</div>
				<div class="row1">
					<div class="row1-1">
						<div class="row1-1tit">
							蓄热模型——偏置
						</div>
						<div class="row1-2">
							<div class="tab2l fl">
								<div>
									<div class="tab2l-1 fl">1#</div>
									<div class="tab2l-2 fl"
										@click="toIpt(infoList.V_RS1.MAXLBSJ_B1, '1#','MAXLBSJ_B1','V_RS1')">
										{{infoList.V_RS1.MAXLBSJ_B1}}</div>
									<div class="tab2l-1 fl">2#</div>
									<div class="tab2l-2 fl"
										@click="toIpt(infoList.V_RS2.MAXLBSJ_B2, '2#','MAXLBSJ_B2','V_RS2')">
										{{infoList.V_RS2.MAXLBSJ_B2}}</div>
									<div class="tab2l-1 fl">3#</div>
									<div class="tab2l-2 fl"
										@click="toIpt(infoList.V_RS3.MAXLBSJ_B3, '3#','MAXLBSJ_B3','V_RS3')">
										{{infoList.V_RS3.MAXLBSJ_B3}}</div>
									<div class="tab2l-1 fl">4#</div>
									<div class="tab2l-2 fl"
										@click="toIpt(infoList.V_RS4.MAXLBSJ_B4, '4#','MAXLBSJ_B4','V_RS4')">
										{{infoList.V_RS4.MAXLBSJ_B4}}</div>
								</div>
							</div>
							<div class="tab3l fl">
								<div>
									<div class="tab3l-1 fl"
										@click="toIpt(infoList.V_RS1.FFQH_B1, infoList.V_RS1.FFQH_B1 ? '1#炉软测量' : '1#炉实际测量','FFQH_B1','V_RS1')">
										{{infoList.V_RS1.FFQH_B1 ? '1#炉软测量' : '1#炉实际测量'}}
									</div>
									<div class="tab3l-2 fr"
										@click="toIpt(infoList.V_RS2.FFQH_B2, infoList.V_RS2.FFQH_B2 ? '2#炉软测量' : '2#炉实际测量','FFQH_B2','V_RS2')">
										{{infoList.V_RS2.FFQH_B2 ? '2#炉软测量' : '2#炉实际测量'}}
									</div>
								</div>
								<div>
									<div class="tab3l-1 fl"
										@click="toIpt(infoList.V_RS3.FFQH_B3, infoList.V_RS3.FFQH_B3 ? '3#炉软测量' : '3#炉实际测量','FFQH_B3','V_RS3')">
										{{infoList.V_RS3.FFQH_B3 ? '3#炉软测量' : '3#炉实际测量'}}
									</div>
									<div class="tab3l-2 fr"
										@click="toIpt(infoList.V_RS4.FFQH_B4, infoList.V_RS4.FFQH_B4 ? '4#炉软测量' : '4#炉实际测量','FFQH_B4','V_RS4')">
										{{infoList.V_RS4.FFQH_B4 ? '4#炉软测量' : '4#炉实际测量'}}
									</div>
								</div>
							</div>
							<div class="tab4l fl">
								煤气流量总量上限
							</div>
							<div>
								<div class="tab5l fl"
									@click="isDisable ? numMin(5000,infoList.V_OTHER2.MQLLZLH,'MQLLZLH','V_OTHER2') : ''">
									-5000</div>
								<div class="tab5l-2 fl"
									@click="isDisable ? numMin(1000,infoList.V_OTHER2.MQLLZLH,'MQLLZLH','V_OTHER2') : ''">
									-1000</div>
								<div class="tab5l-1 fl"
									@click="toIpt(infoList.V_OTHER2.MQLLZLH, '煤气流量总量上限','MQLLZLH','V_OTHER2')"
									style="color:#1662ec">{{infoList.V_OTHER2.MQLLZLH}}</div>
								<div class="tab5l-2 fl"
									@click="isDisable ? numAdd(1000,infoList.V_OTHER2.MQLLZLH,'MQLLZLH','V_OTHER2') : ''">
									+1000</div>
								<div class="tab5l-2 fl"
									@click="isDisable ? numAdd(5000,infoList.V_OTHER2.MQLLZLH,'MQLLZLH','V_OTHER2') : ''">
									+5000</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
		<inputVal ref="inputVal" @getTreeData='DataJson(arguments)'></inputVal>
		 <Historical v-if="isHshow" @sendStatus='isClose' :historyname='historyname' :node='node' :Lkname='Lkname'
			:chName="chName" :infoList='infoList'></Historical>
	</div>
</template>
<script>
	import inputVal from "./inputVal" //输入框组件
	import Historical from "@/components/Historical.vue" //历史趋势
	import {
		downAction
	} from "@/api/index/index"
	export default {
		name: "Param",
		components: {
			inputVal,
			Historical
		},
		props: {
			infoList: {
				type: Object,
				default: {} // 默认值
			}
		},

		data: () => {
			return {
				chName: '',
				isComShow: false,
				isHshow: false,
				//  isClose:false,
				historyname: '',
				node: '',
				Lkname: '',
				isDisable: true,
				spotArr: [],
				grouptime: null
			}

		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
		},
		methods: {
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {

						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			DataJson(data) {
				let strNode = data[0]
				let strMark = data[1]
				this.$nextTick(() => {
					this.infoList[strNode][strMark] = data[2]
				});
			},
			//  参数减
			numMin(addnum, nownum, mark, node) {
				this.isDisable = true
				let newnum = parseFloat(nownum) - parseFloat(addnum)
				this.infoList[node][mark] = newnum.toFixed(2) //保留两位小数
				downAction({
						device: this.projectData.project,
						mark: mark,
						node: node,
						value: this.infoList[node][mark],
						cp_type: this.projectData.type
					})
					.then(res => {
						if (res.info.res == 1) {
							this.isDisable = true
						} else {
							this.$message.error('出错了');
							this.isDisable = true
							this.infoList[node][mark] = nownum
						}
					})
					.catch(err => {
						this.$message.error('下置失败');
						this.isDisable = true
						this.infoList[node][mark] = nownum
					})
			},
			//  参数加
			numAdd(addnum, nownum, mark, node) {
				this.isDisable = false
				let newnum = parseFloat(addnum) + parseFloat(nownum)
				this.infoList[node][mark] = newnum.toFixed(2) //保留两位小数
				downAction({
						device: this.projectData.project,
						mark: mark,
						node: node,
						value: this.infoList[node][mark],
						cp_type: this.projectData.type
					})
					.then(res => {
						if (res.info.res == 1) {
							this.isDisable = true
						} else {
							this.$message.error('出错了');
							this.isDisable = true
							this.infoList[node][mark] = nownum
						}
					})
					.catch(err => {
						this.$message.error('下置失败');
						this.isDisable = true
						this.infoList[node][mark] = nownum
					})
			},
			closeCompon() {
				this.$emit('sendStatus', 'param', false)
			},
			isClose(val, val1) {
				switch (val) {
					case 'Historical':
						return this.isHshow = val1
				}
			},
			toCompon(key, name, name2, name3, name4) {

				this.isIndex = key
				this.historyname = name
				this.node = name2
				this.Lkname = name3
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							return this.isMshow = true
						case 1:
							return this.isFshow = true
						case 2:
							return this.isHshow = true, this.chName = name4
					}
				}, 300)
			},
			toIpt(data, name, historyname, node) {

				this.$refs.inputVal.open(data, name, historyname, node)
			},

		}
	}
</script>
<style lang="scss" scoped>
	.param {
		width: 60vw;
		height: 80vh;
		// background: #e9f7ff;
		background-image: url('~@/assets/images/anquanshezhiditu.png');
		background-size: 100% 100%;
		border-radius: 8px;

		.fl {
			float: left;
		}

		.fr {
			float: right;
		}

		.param-header {
			height: 50px;
			//  background: #5DCEF3;
			line-height: 50px;
			text-align: center;
			color: #041a28;
			font-size: 24px;
			position: relative;
			border-radius: 5px 5px 0 0;

			.param-header-title {
				width: 179px;
				height: 28px;
				font-family: MicrosoftYaHei;
				font-size: 24px;
				font-weight: normal;
				font-stretch: normal;
				line-height: 20px;
				letter-spacing: 0px;
				color: #0ef7ff;
			}

			.param-header-icon {
				width: 2vw;
				height: 2vh;
				position: absolute;
				right: 1vw;
				margin-top: -5vh;
				cursor: pointer;
			}
		}

		.param-tab {

			.param-tab-tit {
				margin-top: 2.4vh;
				width: 100%;
				height: 2.4vh;
				font-family: PingFang-SC-Regular;
				line-height: 0.8vh;
				font-weight: normal;
				font-stretch: normal;
				font-size: 2.8vh;
				text-align: center;
				letter-spacing: 1vw;
				color: #d0e5ec;
			}

			.tab-row {
				width: 58vw;
				height: 65vh;
				margin: 1vh 1vw;
				border-top: 2px solid #00e5ff20;
				;
				border-left: 1px solid #00e5ff20;
				;
				border-bottom: 1px solid #00e5ff20;
				;

				.row1 {
					width: 21vw;
					height: 65vh;
					border-right: 1px solid #00e5ff20;
					;

					.row1-1 {
						height: 4vh;

						.row1-1tit {
							height: 4.7vh;
							font-family: PingFang-SC-Regular;
							font-size: 20px;
							font-weight: normal;
							font-stretch: normal;
							line-height: 4.7vh;
							color: #d4e9f0;
							text-align: center;

						}

						.row1-2 {
							height: 27.6vh;
							color: #8aeaff;
							border-bottom: 1px solid #00e5ff20;
							;
							border-top: 1px solid #00e5ff20;
							;
							font-family: PingFang-SC-Regular;
							font-size: 0.85vw;
							font-weight: normal;
							font-stretch: normal;
							width: 19.2vw;

							.tab1l {
								height: 4.2vh;
								line-height: 4.2vh;
								text-align: center;
								width: 9.5vw;
								color: #8aeaff;
								border-right: 1px solid #00e5ff20;
								;
							}

							.tab1r {
								height: 4.2vh;
								line-height: 4.2vh;
								text-align: center;
								width: 9.5vw;
								color: #d7eff5;

							}

							.tab2-1 {
								height: 3.8vh;
								line-height: 3.8vh;
								text-align: center;
								width: 4.75vw;
							}

							.tab2-2 {
								height: 3.8vh;
								line-height: 3.8vh;
								text-align: center;
								color: #fff;
								width: 4.75vw;
								border-right: 1px solid #00e5ff20;
								;
								cursor: pointer;
							}

							.tab2-2-1 {
								// height: 42px;
								line-height: 3.8vh;
								text-align: center;
								cursor: pointer;

								width: 4.75vw;
								border-right: 1px solid #00e5ff20;
								;
							}

							.tab2-3 {
								height: 3.8vh;
								line-height: 3.8vh;
								text-align: center;
								width: 4.75vw;
							}

							.tab2-4 {
								height: 3.8vh;
								line-height: 3.8vh;
								text-align: center;
								width: 4.75vw;
								color: #fff;
								cursor: pointer;

							}

							.tab2-4-1 {
								// height: 42px;
								line-height: 3.8vh;
								text-align: center;
								width: 4.75vw;
								cursor: pointer;
							}

							.tab2l {
								height: 3.5vh;
								line-height: 42px;
								width: 19.3vw;
								border-bottom: 1px solid #00e5ff20;
								;
							}

							.tab2l-1 {
								height: 3.5vh;
								height: 3.5vh;
								text-align: center;
								width: 1.8vw;
								border-bottom: 1px solid #00e5ff20;
								;
							}

							.tab2l-2 {
								margin-right: 0.3vw;
								height: 3.5vh;
								width: 2.5vw;
								color: #fff;
								border-bottom: 1px solid #00e5ff20;
								;
								cursor: pointer;
							}

							.tab3l {
								height: 10vh;
								width: 19.3vw;
								border-bottom: 1px solid #00e5ff20;
								;

								.tab3l-1 {
									margin-left: 1.6vw;
									margin-top: 1vh;
									margin-right: 1vw;
									cursor: pointer;
									text-align: center;
									width: 7vw;
									height: 3vh;
									background-color: rgba(102, 136, 152, .2);
									box-shadow: 0px 1px 0px 0px rgba(21, 56, 76, .2);
								}

								.tab3l-2 {
									margin-left: 1.6vw;
									margin-top: 1vh;
									margin-right: 1vw;
									text-align: center;
									height: 3vh;
									width: 7vw;

									background-color: rgba(102, 136, 152, .2);
									box-shadow: 0px 1px 0px 0px rgba(21, 56, 76, .2);
								}
							}

							.tab4l {
								height: 5vh;
								;
								width: 19.5vw;
								line-height: 5vh;
								;
								text-align: center;
								//  border-bottom:1px solid #00e5ff20;;
								font-family: PingFang-SC-Regular;
								font-size: 1vw;
								color: #c7e2eb;
							}

							.tab5l {
								margin-top: 1vh;
								margin-left: 0.3vw;
								height: 2.5vh;
								width: 3.1vw;
								line-height: 2.5vh;
								text-align: center;
								background-color: #00162460;
								color: #6bdbfd;
								cursor: pointer;
							}

							.tab5l-1 {
								margin-top: 1vh;
								margin-left: 0.3vw;
								height: 2.5vh;
								width: 5vw;
								line-height: 2.5vh;
								text-align: center;
								background-color: #00162490;
								color: #82dbf7;
								cursor: pointer;
							}

							.tab5l-2 {
								margin-top: 1vh;
								margin-left: 0.3vw;
								height: 2.5vh;
								width: 3.1vw;
								line-height: 2.5vh;
								text-align: center;
								background-color: #00162460;
								color: #6bdbfd;
								cursor: pointer;
							}

						}


					}
				}

				// .row2{
				//   width: 30px;
				//   height: 569px;
				//   border-right: 1px solid #00e5ff20;;
				// }
				// .row3{
				//   width: 0px;
				//   height: 569px;

				// }

			}
		}

	}
</style>
