<template>
	<div class="param11 drag" v-draw id="param1-4">
		<div class="param11-header">
			<span class="param11-header-title">{{projectData.project}}_参数{{datatype}}1</span>
			<div class="param11-header-icon" @click="closeCompon">
				<img src="~@/assets/images/close.png" style="width: 100%; height: auto" />
			</div>
		</div>
		<div class="param11-tab">
			<div class="param11-tab-tit"> {{pkey}}#炉控制参数</div>
			<div class="param11-row-bg">
				<img src="~@/assets/images/rfl1-4.png" />
			</div>
			<div class="param11-row-data">
				<div class="data-t flex">
					<div class="data-le">
						<div class="le1">
							<div class="le-tit"> 初期增加量</div>
							<div class="le-tit1"
								@click="toIpt(infoList[publiParam]['QHSJ_B' + pkey],'初期增加量','QHSJ_B' + pkey,publiParam)">
								{{ infoList[publiParam]['QHSJ_B' + pkey] }}℃
							</div>
						</div>
						<div class="le1" style="border:none">
							<div class="le-tit"> 调整时间</div>
							<div class="le-tit1"
								@click="toIpt(infoList[publiParam]['YWZJSJ_B' + pkey],'调整时间','YWZJSJ_B' + pkey,publiParam)">
								{{ infoList[publiParam]['YWZJSJ_B' + pkey] }}m
							</div>
						</div>
					</div>
					<div class="data-le1">
						<div class="le-tit">预测烟温</div>
						<div class="le-tit1"
							@click="toCompon(2, 'YCFQWD_B' + pkey, publiParam, 'YCFQWD_B' + pkey +'_WF','1#炉预测废气温度')"
							@dblclick="Cclick(infoList[publiParam]['YCFQWD_B' + pkey],'YCFQWD_B' + pkey, publiParam, 'YCFQWD_B' + pkey +'_WF')">
							{{ infoList[publiParam]['YCFQWD_B' + pkey] }}
						</div>
					</div>
					<div class="data-le2">
						<div class="le-tit">煤气设定</div>
						<div class="le-tit1"
							@click="toIpt(infoList.V_SOUREC['FQSP_B' + pkey],'煤气设定','FQSP_B' + pkey,'V_SOUREC')">
							{{ infoList.V_SOUREC['FQSP_B' + pkey] }}
						</div>
					</div>
					<div class="data-le3">
						<div class="buttonx buttonx1" :style="{
                  background: !infoList['V_RS__p__RSFB' + pkey + '__p__XK04'].TS
                    ? '#2AFC30'
                    : 'red',
                }" @click="infoList['V_RS__p__RSFB' + pkey + '__p__XK04'] ? toDetail(1,commonParam,'V_RS__p__RSFB' + pkey + '__p__XK04','', pkey + '#废气温度调节先控' ): ''">
							X
						</div>
					</div>
					<el-tooltip class="item" effect="dark" :content="infoList[publiParam]['MQSP_B' + pkey]"
						placement="top">
						<div class="data-le4" @click="toCompon(2, 'MQSP_B' + pkey, publiParam, 'MQSP_B' + pkey + '_WF','1#煤气设定值')"
							@dblclick="Cclick(infoList[publiParam]['MQSP_B' + pkey],'MQSP_B' + pkey, publiParam, 'MQSP_B' + pkey +'_WF')">
							{{ infoList[publiParam]['MQSP_B' + pkey] }}
						</div>
					</el-tooltip>
					<div class="data-le" style="margin-left: 4vw;width:4vw">
						<div class="le1">
							<div class="le-tit"> 初始强度</div>
							<div class="le-tit1"
								@click="toIpt(infoList[publiParam]['TGY_B' + pkey],'初始强度','TGY_B' + pkey,publiParam)">
								{{ infoList[publiParam]['TGY_B' + pkey] }}
							</div>
						</div>
						<div class="le1" style="border:none">
							<div class="le-tit"> 修正常数</div>
							<div class="le-tit1"
								@click="toIpt(infoList[publiParam]['JBKG02_B' + pkey],'修正常数','JBKG02_B' + pkey,publiParam)">
								{{ infoList[publiParam]['JBKG02_B' + pkey] }}
							</div>
						</div>
					</div>
					<div class="data-le5">
						<div class="le-tit">烧炉强度</div>
						<div class="le-tit1"
							@click="toIpt(infoList[publiParam]['KQSP02_B' + pkey],'烧炉强度','KQSP02_B' + pkey,publiParam)">
							{{ infoList[publiParam]['KQSP02_B' + pkey] }}
						</div>
					</div>
					<div class="data-le6 ma-auto flex">
						<div class="le6-row1-le">空<br>气</div>

						<div class="list">
							<div class="le6-row1-list flex">
								<div class="tit">阀位</div>
								<el-tooltip class="item" effect="dark" placement="top"
									v-for="(item, index) in commonArray" :key="index"
									:content="infoList['V_RS__p__RSFB' + pkey + '__p__HSCHARC3']['X__ll__' + item + '__rr__']">
									<div
										@click="toIpt(infoList['V_RS__p__RSFB' + pkey + '__p__HSCHARC3']['X__ll__' + item + '__rr__'],'阀位', 'X__ll__' + item + '__rr__', 'V_RS__p__RSFB' + pkey + '__p__HSCHARC3')">
										{{infoList['V_RS__p__RSFB' + pkey + '__p__HSCHARC3']['X__ll__' + item + '__rr__']}}
									</div>
								</el-tooltip>
							</div>
							<div class="le6-row1-list flex">
								<div class="tit">系数</div>
								<el-tooltip class="item" effect="dark" placement="top"
									v-for="(item, index) in commonArray" :key="index"
									:content="infoList['V_RS__p__RSFB' + pkey + '__p__HSCHARC3']['Y__ll__' + item + '__rr__']">
									<div
										@click="toIpt(infoList['V_RS__p__RSFB' + pkey + '__p__HSCHARC3']['Y__ll__' + item + '__rr__'],'系数', 'Y__ll__' + item + '__rr__', 'V_RS__p__RSFB' + pkey + '__p__HSCHARC3')">
										{{infoList['V_RS__p__RSFB' + pkey + '__p__HSCHARC3']['Y__ll__' + item + '__rr__']}}
									</div>
								</el-tooltip>
							</div>
							<div class="le6-row1-list flex">
								<div class="tit">压力</div>
								<el-tooltip class="item" effect="dark" placement="top"
									v-for="(item, index) in commonArray" :key="index"
									:content="infoList['V_RS__p__RSFB' + pkey + '__p__HSCHARC4']['X__ll__' + item + '__rr__']">
									<div
										@click="toIpt(infoList['V_RS__p__RSFB' + pkey + '__p__HSCHARC4']['X__ll__' + item + '__rr__'],'压力', 'X__ll__' + item + '__rr__', 'V_RS__p__RSFB' + pkey + '__p__HSCHARC4')">
										{{infoList['V_RS__p__RSFB' + pkey + '__p__HSCHARC4']['X__ll__' + item + '__rr__']}}
									</div>
								</el-tooltip>
							</div>
							<div class="le6-row1-list flex">
								<div class="tit">系数</div>
								<el-tooltip class="item" effect="dark" placement="top"
									v-for="(item, index) in commonArray" :key="index"
									:content="infoList['V_RS__p__RSFB' + pkey + '__p__HSCHARC4']['Y__ll__' + item + '__rr__']">
									<div
										@click="toIpt(infoList['V_RS__p__RSFB' + pkey + '__p__HSCHARC4']['Y__ll__' + item + '__rr__'],'系数', 'Y__ll__' + item + '__rr__', 'V_RS__p__RSFB' + pkey + '__p__HSCHARC4')">
										{{infoList['V_RS__p__RSFB' + pkey + '__p__HSCHARC4']['Y__ll__' + item + '__rr__']}}
									</div>
								</el-tooltip>
							</div>
						</div>
					</div>
				</div>
				<div class="data-c flex align-cen">
					<div class="datac-le">
						<div class="datac-le-tit">协调减少量</div>
						<div class="datac-le-tit1"
							@click="toCompon(2, 'KQSP01_B' + pkey, publiParam, 'KQSP01_B' + pkey + '_WF','1#炉优先烧炉减少煤气量')"
							@dblclick="Cclick(infoList[publiParam]['KQSP01_B' + pkey],'KQSP01_B' + pkey, publiParam, 'KQSP01_B' + pkey + '_WF')">
							{{ infoList[publiParam]['KQSP01_B' + pkey] }}
						</div>
					</div>
					<div class="datac-le-img">
						<img src="~@/assets/images/rfl-add.png" />
					</div>
					<div class="datac-le">
						<div class="datac-le-tit">基本强度</div>
						<div class="datac-le-tit1"
							@click="toIpt(infoList[publiParam]['JBMQL_B' + pkey],'基本强度','JBMQL_B' + pkey, publiParam)"
							style="color: #01ffba;">
							{{ infoList[publiParam]['JBMQL_B' + pkey] }}
						</div>
					</div>
					<div class="datac-le-img">
						<img src="~@/assets/images/rfl-add.png" />
					</div>
					<div class="datac-le">
						<div class="datac-le-tit">单烧增量</div>
						<div class="datac-le-tit1"
							@click="toIpt(infoList[publiParam]['DSZL_B' + pkey],'单烧增量','DSZL_B' + pkey, publiParam)"
							style="color: #01ffba;">
							{{ infoList[publiParam]['DSZL_B' + pkey] }}
						</div>
					</div>
					<div class="datac-le">
						<div class="datac-le-tit">保留系数</div>
						<div class="datac-le-tit1"
							@click="toIpt(infoList[publiParam]['FSPM_B' + pkey],'保留系数','FSPM_B2' + pkey, publiParam)"
							style="color: #01ffba;">
							{{ infoList[publiParam]['FSPM_B' + pkey] }}
						</div>
					</div>
				</div>
				<div class="data-c-r">
					<div class="rt flex">
						<div class="rt-t">
							<div class="rt-le">
								空气流量设定值
							</div>
							<div class="rt-le1"
								@click="toCompon(2, 'KQSP_B' + pkey, publiParam, 'KQSP_B' + pkey + '_WF','1#空气设定值')"
								@dblclick="Cclick(infoList[publiParam]['KQSP_B' + pkey],'KQSP_B' + pkey, publiParam, 'KQSP_B' + pkey + '_WF')">
								{{ infoList[publiParam]['KQSP_B' + pkey] }}
							</div>
						</div>
						<div class="rt-ma ma-auto">
							<div class="buttonx buttonx2" :style="{
                  background: !infoList['V_RS__p__RSFB' + pkey + '__p__XK02'].TS
                    ? '#2AFC30'
                    : 'red',
                }" @click="infoList['V_RS__p__RSFB' + pkey + '__p__XK02'] ? toDetail(1,commonParam1,'V_RS__p__RSFB' + pkey + '__p__XK02','', pkey + '#空气流量先控' ): ''">
								X
							</div>
						</div>
						<div class="rt-ma1 ma-auto"
							@click="toCompon(2, 'AV', 'V_RS__p__RSFB' + pkey + '__p__XK02', commonParam1 + '_AV_WF','1#助燃风机压力先控总输出')"
							@dblclick="Cclick(infoList['V_RS__p__RSFB' + pkey + '__p__XK02'].AV,'AV', 'V_RS__p__RSFB' + pkey + '__p__XK02', commonParam1 + '_AV_WF')">
							{{infoList['V_RS__p__RSFB' + pkey + '__p__XK02'].AV}}
						</div>
					</div>
					<div class="rb flex">
						<div class="data-le" style="margin-top: 1vh;width:4vw;margin-left:-1vw">
							<div class="le1">
								<div class="le-tit"> 空气上限</div>
								<div class="le-tit1"
									@click="toIpt(infoList.V_HLLIM['HLLIMH' + pkey + '2'],'空气上限','HLLIMH' + pkey + '2','V_HLLIM')">
									{{infoList.V_HLLIM['HLLIMH' + pkey + '2']}}
								</div>
							</div>
							<div class="le1" style="border:none">
								<div class="le-tit"> 空气下限</div>
								<div class="le-tit1"
									@click="toIpt(infoList.V_HLLIM['HLLIML' + pkey + '2'],'空气下限','HLLIML' + pkey + '2','V_HLLIM')">
									{{infoList.V_HLLIM['HLLIML' + pkey + '2']}}
								</div>
							</div>
						</div>
						<div class="rt-t" style="margin-top:4vh;margin-left:1vw;cursor: pointer">
							<div class="rt-le">
								空气流量
							</div>
							<div class="rt-le1"
								@click="toCompon(2, 'PV', 'V_RS__p__RSFB' + pkey + '__p__XK02', commonParam1 + '_PV_WF','1#助燃风机压力先控测量值')"
								@dblclick="Cclick(infoList['V_RS__p__RSFB' + pkey + '__p__XK02'].PV,'PV', 'V_RS__p__RSFB' + pkey + '__p__XK02', commonParam1 + '_PV_WF')">
								{{infoList['V_RS__p__RSFB' + pkey + '__p__XK02'].PV}}
							</div>
						</div>
						<div class="rb-ma ma-auto">
							<div class="rb-ma-row flex">
								<div class="text">
									初始阀位增量
								</div>
								<div class="ipt textov"
									@click="toIpt(infoList.V_GONGGONG['CSKQFWZL_B' + pkey],'初始阀位增量','CSKQFWZL_B' + pkey,'V_GONGGONG')">
									{{infoList.V_GONGGONG['CSKQFWZL_B' + pkey]}}
								</div>
							</div>
							<div class="rb-ma-row flex">
								<div class="text">
									空压前馈系数
								</div>
								<div class="ipt textov"
									@click="toIpt(infoList[publiParam]['KM_KQ_B' + pkey],'空压前馈系数','KM_KQ_B' + pkey,publiParam)">
									{{infoList[publiParam]['KM_KQ_B' + pkey]}}
								</div>
							</div>
							<div class="rb-ma-row flex">
								<div class="text">
									前馈输出
								</div>
								<div class="ipt textov"
									@click="toCompon(2, 'FV_HH_B' + pkey, publiParam, 'FV_HH_B' + pkey + '_WF','1#炉空气阀位前馈量')"
									@dblclick="Cclick(infoList[publiParam]['FV_HH_B' + pkey],'FV_HH_B' + pkey, publiParam, 'FV_HH_B' + pkey + '_WF')"
									style="color:#8aeaff">
									{{infoList[publiParam]['FV_HH_B' + pkey]}}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="data-b">
					<div class="rt-t " style="margin-left:22.5vw;margin-top:-2vh;width:4vw;line-height:2.5vh">
						<div class="rt-le textov">
							优化风煤比
						</div>
						<div class="rt-le1" style="text-align:center;cursor: pointer;color:#01ffba"
							@click="toIpt(infoList['V_BCS' + pkey]['YHFMB_B' + pkey],'优化风煤比','YHFMB_B' + pkey,'V_BCS' + pkey)">
							{{infoList['V_BCS' + pkey]['YHFMB_B' + pkey]}}
						</div>
					</div>
					<div class="data-b-c flex">
						<div class="rt-t">
							<div class="rt-le">
								含氧量设定
							</div>
							<div class="rt-le1"
								@click="toIpt(infoList.V_SOUREC['ZJZQ_B' + pkey],'含氧量设定','ZJZQ_B' + pkey,'V_SOUREC')">
								{{infoList.V_SOUREC['ZJZQ_B' + pkey]}}
							</div>
						</div>
						<div class="buttonx buttonx3" :style="{
                  background: !infoList['V_RS__p__RSFB' + pkey + '__p__XK05'].TS
                    ? '#2AFC30'
                    : 'red',
                }" @click="infoList['V_RS__p__RSFB' + pkey + '__p__XK05'] ? toDetail(1,commonParam2,'V_RS__p__RSFB' + pkey + '__p__XK05','', pkey + '#拱顶温度调节空燃比先控' ): ''">
							X
						</div>
						<div class="rt-t1">
							<div class="rt-le">
								顶温下降调配比系数
							</div>
							<div class="rt-le1"
								@click="toIpt(infoList[publiParam]['GDBL_B' + pkey],'顶温下降调配比系数','GDBL_B' + pkey,publiParam)">
								{{infoList[publiParam]['GDBL_B' + pkey]}}
							</div>
						</div>
						<div class="rt-t1" style="margin-left:1.5vw">
							<div class="rt-le">
								干预风煤比
							</div>
							<div class="rt-le1" style="color:#2fc3e3"
								@click="toCompon(2, 'AV', 'V_RS__p__RSFB' + pkey + '__p__XK05', commonParam2 + '_AV_WF')"
								@dblclick="Cclick(infoList['V_RS__p__RSFB' + pkey + '__p__XK05'].AV,'AV', 'V_RS__p__RSFB' + pkey + '__p__XK05', commonParam2 + '_AV_WF')">
								{{infoList['V_RS__p__RSFB' + pkey + '__p__XK05'].AV ==0 ? '0.00' : infoList['V_RS__p__RSFB' + pkey + '__p__XK05'].AV}}
							</div>
						</div>
					</div>
				</div>
				<div class="data-b1">
					<div class="rt-t">
						<div class="rt-le">
							废气含氧量
						</div>
						<div class="rt-le1" @click="toCompon(2, 'TE_14_B' + pkey, 'V_SOUREC', 'TE_14_B' + pkey + '_WF','1#热风炉废气含氧量')"
							@dblclick="Cclick(infoList.V_SOUREC['TE_14_B' + pkey],'TE_14_B' + pkey, 'V_SOUREC', 'TE_14_B' + pkey + '_WF')">
							{{infoList.V_SOUREC['TE_14_B' + pkey]}}
						</div>
					</div>
					<div class="buttonx buttonr" :style="{
                  background: !infoList['V_RS__p__RSFB' + pkey + '__p__RSF1'].TS
                    ? '#2AFC30'
                    : 'red',
                }" @click="infoList['V_RS__p__RSFB' + pkey + '__p__RSF1'] ? 
                (2,commonParam3,'V_RS__p__RSFB' + pkey + '__p__RSF1','', pkey + '#废气含氧量高调节' ): ''">
						R
					</div>
					<div class="buttonx buttonr1" :style="{
                  background: !infoList['V_RS__p__RSFB' + pkey + '__p__RSF2'].TS
                    ? '#2AFC30'
                    : 'red',
                }" @click="infoList['V_RS__p__RSFB' + pkey + '__p__RSF2'] ? toDetail(2,commonParam4,'V_RS__p__RSFB' + pkey + '__p__RSF2','', pkey + '#废气含氧量低调节' ): ''">
						R
					</div>
					<div class="data-b1-num"
						@click="toCompon(2,'AV','V_RS__p__RSFB' + pkey + '__p__RSF1', commonParam3 + '_AV_WF')"
						@dblclick="Cclick(infoList['V_RS__p__RSFB' + pkey + '__p__RSF1'].AV,'AV' , 'V_RS__p__RSFB' + pkey + '__p__RSF1', commonParam3 + '_AV_WF')">
						{{infoList['V_RS__p__RSFB' + pkey + '__p__RSF1'].AV}}
					</div>
					<div class="data-b1-num1"
						@click="toCompon(2,'AV','V_RS__p__RSFB' + pkey + '__p__RSF2', commonParam4 + '_AV_WF')"
						@dblclick="Cclick(infoList['V_RS__p__RSFB' + pkey + '__p__RSF2'].AV,'AV' , 'V_RS__p__RSFB' + pkey + '__p__RSF2', commonParam4 + '_AV_WF')">
						{{infoList['V_RS__p__RSFB' + pkey + '__p__RSF2'].AV}}
					</div>
					<div class="data-b1-btn"
						@click=" toIpt(infoList[publiParam]['SPQHSJ_B' + pkey], '按钮', 'SPQHSJ_B' + pkey, publiParam)">
						{{infoList[publiParam]['SPQHSJ_B' + pkey] ? "启动" : "屏蔽" }}
					</div>
					<div class="data-b1-num2" @click="toCompon(2,'MQSP_B' + pkey,publiParam, 'MQSP_B' + pkey + '_WF','2#煤气设定值')"
						@dblclick="Cclick(iinfoList[publiParam]['MQSP_B' + pkey],'MQSP_B' + pkey , publiParam, 'MQSP_B' + pkey + '_WF')">
						{{ infoList[publiParam]['MQSP_B' + pkey] }}
					</div>
					<div class="buttonx buttonx4" :style="{
                  background: !infoList['V_RS__p__RSFB' + pkey + '__p__XK01'].TS
                    ? '#2AFC30'
                    : 'red',
                }" @click="infoList['V_RS__p__RSFB' + pkey + '__p__XK01'] ? toDetail(1,commonParam5,'V_RS__p__RSFB' + pkey + '__p__XK01','', pkey + '#煤气流量先控' ): ''">
						X
					</div>
					<div class="data-b1-num3"
						@click="toCompon(2, 'AV', 'V_RS__p__RSFB' + pkey + '__p__XK01', commonParam5 + '_AV_WF','煤气总管压力先控总输出')"
						@dblclick="Cclick(infoList['V_RS__p__RSFB' + pkey + '__p__XK01'].AV,'AV', 'V_RS__p__RSFB' + pkey + '__p__XK01', commonParam5 + '_AV_WF')">
						{{infoList['V_RS__p__RSFB' + pkey + '__p__XK01'].AV ==0 ? '0.00' : infoList['V_RS__p__RSFB' + pkey + '__p__XK01'].AV}}
					</div>
					<div class="otherhis"
						@click="toCompon(2, 'LINGPAI', 'V_RS__p__RSFB' + pkey, 'LINGPAI' + pkey + '_WF','令牌')"
						@dblclick="Cclick(infoList['V_RS__p__RSFB' + pkey].LINGPAI,'LINGPAI', 'V_RS__p__RSFB' + pkey, 'LINGPAI' + pkey + '_WF')">
						其他
					</div>
					<div class="data-le data-b1-le">
						<div class="le1">
							<div class="le-tit"> 煤气上限</div>
							<div class="le-tit1"
								@click="toIpt(infoList.V_HLLIM['HLLIMH' + pkey + '1'],'空气上限','HLLIMH' + pkey + '1','V_HLLIM')">
								{{infoList.V_HLLIM['HLLIMH' + pkey + '1']}}
							</div>
						</div>
						<div class="le1" style="border:none">
							<div class="le-tit"> 煤气下限</div>
							<div class="le-tit1"
								@click="toIpt(infoList.V_HLLIM['HLLIML' + pkey + '1'],'煤气下限','HLLIML' + pkey + '','V_HLLIM')">
								{{infoList.V_HLLIM['HLLIML' + pkey + '1']}}
							</div>
						</div>
					</div>
					<div class="rt-t1">
						<div class="rt-le">
							煤气流量
						</div>
						<div class="rt-le1" style="color:#2fc3e3"
							@click="toCompon(2, 'PV', 'V_RS__p__RSFB' + pkey + '__p__XK01', commonParam5 + '_PV_WF','煤气总管压力先控测量值')"
							@dblclick="Cclick(infoList['V_RS__p__RSFB' + pkey + '__p__XK01'].PV,'PV', 'V_RS__p__RSFB' + pkey + '__p__XK01', commonParam5 + '_PV_WF')">
							{{infoList['V_RS__p__RSFB' + pkey + '__p__XK01'].PV ==0 ? '0.00' : infoList['V_RS__p__RSFB' + pkey + '__p__XK01'].PV}}
						</div>
						<div class="startbutton2" style="position: relative">
							{{ infoList.V_OTHER1['SEL' + pkey + '06'] ? "补偿启停" : "停止补偿" }}
						</div>
					</div>
					<div class="rb-ma ">
						<div class="rb-ma-row flex">
							<div class="text">
								初始阀位增量
							</div>
							<div class="ipt textov"
								@click="toIpt(infoList.V_GONGGONG['CSMQFWZL_B' + pkey],'初始阀位增量','CSMQFWZL_B' + pkey,'V_GONGGONG')">
								{{infoList.V_GONGGONG['CSMQFWZL_B' + pkey]}}
							</div>
						</div>
						<div class="rb-ma-row flex">
							<div class="text">
								煤压前馈系数
							</div>
							<div class="ipt textov"
								@click="toIpt(infoList['V_BCS' + pkey]['ZJSJ_B' + pkey],'煤压前馈系数','ZJSJ_B' + pkey,'V_BCS' + pkey)">
								{{infoList['V_BCS' + pkey]['ZJSJ_B' + pkey]}}
							</div>
						</div>
						<div class="rb-ma-row flex">
							<div class="text">
								前馈输出
							</div>
							<div class="ipt textov"
								@click="toCompon(2, 'ZJC_B' + pkey, 'V_BCS' + pkey, 'ZJC_B' + pkey + '_WF','专家常数')"
								@dblclick="Cclick(infoList['V_BCS' + pkey]['ZJC_B' + pkey],'ZJC_B' + pkey, 'V_BCS' + pkey, 'ZJC_B' + pkey + '_WF')"
								style="color:#8aeaff">
								{{infoList['V_BCS' + pkey]['ZJC_B' + pkey]}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="param11-row-bottom">
				<div class="data-le6 flex">
					<div class="le6-row1-le">煤<br>气</div>
					<div class="list">
						<div class="le6-row1-list flex">
							<div class="tit">阀位</div>
							<el-tooltip class="item" effect="dark" placement="top" v-for="(item, index) in commonArray"
								:key="index"
								:content="infoList['V_RS__p__RSFB' + pkey + '__p__HSCHARC1']['X__ll__' + item + '__rr__']">
								<div
									@click="toIpt(infoList['V_RS__p__RSFB' + pkey + '__p__HSCHARC1']['X__ll__' + item + '__rr__'],'阀位', 'X__ll__' + item + '__rr__', 'V_RS__p__RSFB' + pkey + '__p__HSCHARC1')">
									{{infoList['V_RS__p__RSFB' + pkey + '__p__HSCHARC1']['X__ll__' + item + '__rr__']}}
								</div>
							</el-tooltip>
						</div>
						<div class="le6-row1-list flex">
							<div class="tit">系数</div>
							<el-tooltip class="item" effect="dark" placement="top" v-for="(item, index) in commonArray"
								:key="index"
								:content="infoList['V_RS__p__RSFB' + pkey + '__p__HSCHARC1']['Y__ll__' + item + '__rr__']">
								<div
									@click="toIpt(infoList['V_RS__p__RSFB' + pkey + '__p__HSCHARC1']['Y__ll__' + item + '__rr__'],'系数', 'Y__ll__' + item + '__rr__', 'V_RS__p__RSFB' + pkey + '__p__HSCHARC1')">
									{{infoList['V_RS__p__RSFB' + pkey + '__p__HSCHARC1']['Y__ll__' + item + '__rr__']}}
								</div>
							</el-tooltip>
						</div>
						<div class="le6-row1-list flex">
							<div class="tit">压力</div>
							<el-tooltip class="item" effect="dark" placement="top" v-for="(item, index) in commonArray"
								:key="index"
								:content="infoList['V_RS__p__RSFB' + pkey + '__p__HSCHARC2']['X__ll__' + item + '__rr__']">
								<div
									@click="toIpt(infoList['V_RS__p__RSFB' + pkey + '__p__HSCHARC2']['X__ll__' + item + '__rr__'],'压力', 'X__ll__' + item + '__rr__', 'V_RS__p__RSFB' + pkey + '__p__HSCHARC2')">
									{{infoList['V_RS__p__RSFB' + pkey + '__p__HSCHARC2']['X__ll__' + item + '__rr__']}}
								</div>
							</el-tooltip>
						</div>
						<div class="le6-row1-list flex">
							<div class="tit">系数</div>
							<el-tooltip class="item" effect="dark" placement="top" v-for="(item, index) in commonArray"
								:key="index"
								:content="infoList['V_RS__p__RSFB' + pkey + '__p__HSCHARC2']['Y__ll__' + item + '__rr__']">
								<div
									@click="toIpt(infoList['V_RS__p__RSFB' + pkey + '__p__HSCHARC2']['Y__ll__' + item + '__rr__'],'系数', 'Y__ll__' + item + '__rr__', 'V_RS__p__RSFB' + pkey + '__p__HSCHARC2')">
									{{infoList['V_RS__p__RSFB' + pkey + '__p__HSCHARC2']['Y__ll__' + item + '__rr__']}}
								</div>
							</el-tooltip>
						</div>
					</div>
				</div>
				<div class="bottom-yh" @click="isComShow = true">
					优化参数
				</div>
			</div>
		</div>
		<inputVal ref="inputVal" @getTreeData="DataJson(arguments)"></inputVal>
		 <Historical v-if="isHshow" @sendStatus="isHshow = false" :historyname="historyname" :node="node"
			:chName="chName" :Lkname="Lkname" :infoList="infoList"></Historical>
		<Firstcontrol :infoList="infoList" :isIndex="isIndex" :historyname="Firstcontrolname"
			:titname="Firstcontroltitname" :node="Firstcontrolnode" v-if="isFshow" @sendStatus="isFshow = false">
		</Firstcontrol>
		<Rsf v-if="isRshow" @sendStatus="isRshow = false" :titname="Rsftitname" :infoList="infoList"
			:historyname="Rsfname" :node="Rsfnode"></Rsf>
		<ParameterYh v-if="isComShow" :infoList="infoList" @sendStatus="isshowfase"></ParameterYh>
	</div>
</template>
<script>
	import inputVal from "./inputVal"; //输入框组件
	import Historical from "@/components/Historical.vue"; //历史趋势
	import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
	import Rsf from "@/components/RSF.vue"; //阮伺服组件
	import ParameterYh from "@/components/ParameterYh.vue"; //参数YH组件
	export default {
		name: "Param11",
		props: {
			infoList: {
				type: Object,
				default: {}, // 默认值
			},
			datatype: [String, Number], //接口返回的点名
		},
		components: {
			inputVal,
			Historical,
			Firstcontrol,
			Rsf,
			ParameterYh
		},
		data: () => {
			return {
				chName: '',
				isComShow: false,
				isHshow: false,
				isMshow: false,
				isRshow: false,
				historyname: "",
				node: "",
				Lkname: "",
				isIndex: "",
				Firstcontrolname: "",
				Firstcontroltitname: "",
				Firstcontrolnode: "",
				isFshow: false,
				Rsfname: "",
				Rsfnode: "",
				Rsftitname: "",
				authInfo: '',
				UserInfo: '',
				spotArr: [],
				grouptime: null,
				publiParam: 'V_RS1',
				pkey: '1',
				commonParam: 'XKA04',
				commonParam1: 'XKA02',
				commonParam2: 'XKA05',
				commonParam3: 'RSFA1',
				commonParam4: 'RSFA2',
				commonParam5: 'XKA01',
				commonArray: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
			};
		},
		watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n
				},
				deep: true // 深度监听父组件传过来对象变化
			},
			datatype: {
				handler(n, o) {
					this.datatype = n;
				},
				deep: true, // 深度监听父组件传过来对象变化
			},
		},
		created() {
			this.publiParam = 'V_RS' + this.datatype
			this.pkey = this.datatype
			this.commonParam = this.datatype == 1 ? 'XKA04' : this.datatype == 2 ? 'XKB04' : this.datatype == 3 ? 'XKC04' :
				'XKD04'
			this.commonParam1 = this.datatype == 1 ? 'XKA02' : this.datatype == 2 ? 'XKB02' : this.datatype == 3 ?
				'XKC02' : 'XKD02'
			this.commonParam2 = this.datatype == 1 ? 'XKA05' : this.datatype == 2 ? 'XKB05' : this.datatype == 3 ?
				'XKC05' : 'XKD05'
			this.commonParam3 = this.datatype == 1 ? 'RSFA1' : this.datatype == 2 ? 'RSFB1' : this.datatype == 3 ?
				'RSFC1' : 'RSFD1'
			this.commonParam4 = this.datatype == 1 ? 'RSFA2' : this.datatype == 2 ? 'RSFB2' : this.datatype == 3 ?
				'RSFC2' : 'RSFD2'
			this.commonParam5 = this.datatype == 1 ? 'XKA01' : this.datatype == 2 ? 'XKB01' : this.datatype == 3 ?
				'XKC01' : 'XKD01'
			this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			this.authInfo = JSON.parse(localStorage.getItem("autharr"))
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
		},
		methods: {
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {

						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			// 打开先控多窗口
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0]
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error('暂无调试权限');
					}
				} else {
					let pathname = ""
					switch (key) {
						case 1:
							pathname = 'firstcontol'
							break
						case 2:
							pathname = 'Rfirstcontol'
							break
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname
						}
					})
					window.open(newurl.href, '_blank')
				}
			},
			// 获取自组件传过来的值
			DataJson(data) {
				let strNode = data[0];
				let strMark = data[1];
				this.$nextTick(() => {
					this.infoList[strNode][strMark] = data[2];
				});
			},
			isshowfase() {
				this.isComShow = false;
			},
			closeCompon() {
				this.$emit("sendStatus", "param11", false);
			},
			isClose(val, val1) {
				switch (val) {
					case "Historical":
						return (this.isHshow = val1);
				}
			},
			toIpt(data, name, historyname, node) {
				this.$refs.inputVal.open(data, name, historyname, node);
			},
			toCompon(key, name, name2, name3, name4, titname) {
				this.isIndex = key;
				this.historyname = name;
				this.node = name2;
				this.Lkname = name3;
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							return (
								(this.isMshow = true),
								(this.Manualname = name),
								(this.Manualnode = name2),
								(this.ManualAname = name3),
								(this.Manualtitname = titname)
							);
						case 1:
							return (
								(this.isFshow = true),
								(this.Firstcontrolname = name),
								(this.Firstcontrolnode = name2),
								(this.Firstcontroltitname = titname)
							);
						case 2:
							return (this.isHshow = true),
							(this.chName = name4);
						case 4:
							return (
								(this.isRshow = true),
								(this.Rsfname = name),
								(this.Rsfnode = name2),
								(this.Rsftitname = titname)
							);
					}
				}, 300)

			},
		},
	};
</script>

<style lang="scss" scoped>
	.param11 {
		width: 53vw;
		height: 67vh;
		background-image: url("~@/assets/images/jrl-param.png");
		background-size: 100% 100%;

		// .otherhis{
		//   color: #fff;
		//   font-size: 1vw;
		//   position: absolute;
		//   left: 530px;
		//   bottom: -10px;
		// }
		.fl {
			float: left;
		}

		.fr {
			float: right;
		}

		.param11-header {
			height: 5vh;
			//  background: #5DCEF3;
			line-height: 6vh;
			text-align: center;
			color: #041a28;
			font-size: 24px;
			position: relative;
			border-radius: 5px 5px 0 0;

			.param11-header-title {
				width: 179px;
				height: 28px;
				font-family: MicrosoftYaHei;
				font-size: 24px;
				font-weight: normal;
				font-stretch: normal;
				line-height: 20px;
				letter-spacing: 0px;
				color: #0ef7ff;
			}

			.param11-header-icon {
				width: 2vw;
				height: 2vh;
				position: absolute;
				right: 1vw;
				margin-top: -5vh;
				cursor: pointer;
			}
		}

		.param11-tab {
			position: relative;

			.param11-tab-tit {
				margin-top: 2vh;
				width: 100%;
				height: 26px;
				font-family: PingFang-SC-Regular;
				line-height: 20px;
				font-weight: normal;
				font-stretch: normal;
				font-size: 24px;
				text-align: center;
				letter-spacing: 6px;
				color: #fff;
			}

			.param11-row-bg {
				width: 42vw;
				height: 36vh;
				// background: url("~@/assets/images/rfl1-4.png") no-repeat 0 0 ;
				// background-size: 100% 100%;
				position: relative;
				margin: 0 auto;
				position: absolute;
				left: 6.5vw;
				top: 8vh;

				img {
					width: 42vw;
					height: 36vh;
				}
			}

			.param11-row-data {
				width: 49vw;
				height: 36vh;
				margin: 0 auto;
				margin-top: 5.5vh;
				position: relative;
				z-index: 99;
				font-size: .7vw;
				color: #2fc3e3;

				.data-c {
					width: 18.4vw;
					height: 6vh;
					background-color: #001b2860;
					border: solid 1px #236f8d;
					margin-top: -3vh;
					position: relative;

					.datac-le {
						width: 3.6vw;
						height: inherit;
						text-align: center;
						overflow: hidden;

						.datac-le-tit {
							overflow: hidden;
							height: 3vh;
							line-height: 3vh;
						}

						.datac-le-tit1 {
							cursor: pointer;
							height: 3vh;
							line-height: 3vh;
						}
					}

					.datac-le-img {

						img {
							width: 100%;
							height: 100%;
						}
					}
				}

				.data-c-r {
					width: 16vw;
					height: 12vh;
					// background: #fff;
					position: absolute;
					right: -1vw;
					top: 8vh;

					.rt {
						height: 5vh;
						position: relative;

						.rt-t {
							text-align: center;
							margin-left: -1vw;
							margin-top: 1vh;

							.rt-le1 {
								cursor: pointer;
							}
						}

						.rt-ma {
							position: absolute;
							bottom: 5px;
							left: 8vw;
							width: 1vw;
							height: 2vh;

							.buttonx2 {
								// margin-top: 4vh;
								margin-right: 6vw;
							}
						}

						.rt-ma1 {
							position: absolute;
							bottom: 0px;
							right: 1vw;
							cursor: pointer;
						}
					}

					.rb-ma {
						width: 7vw;
						height: 8vh;
						background-color: #001b2860;
						border: solid 1px #236f8d;
						margin-right: 1vw;
						margin-top: 1vh;

						.rb-ma-row {
							width: 100%;
							height: 2.66vh;
							line-height: 2.66vh;
							text-align: center;

							.text {
								width: 4vw;
								text-align: right;
								overflow: hidden;
							}

							.ipt {
								width: 3vw;
								text-align: center;
								cursor: pointer;
								color: #01ffba;
							}
						}
					}
				}

				.data-b {

					.data-b-c {

						position: absolute;
						bottom: 11vh;
						left: 4.5vw;

						.rt-t {
							width: 5vw;

							.rt-le {
								text-align: center;
							}

							.rt-le1 {
								cursor: pointer;
								text-align: center;
								color: #01ffba;
							}
						}

						.buttonx3 {
							margin-top: 18px;
						}

						.rt-t1 {
							line-height: 1.8vh;
							margin-top: -1vh;

							.rt-le1 {
								cursor: pointer;
								text-align: center;
								color: #01ffba;
							}
						}
					}
				}

				.data-b1 {
					position: absolute;
					bottom: 3vh;

					.rt-t {
						text-align: center;
						width: 5vw;

						.rt-le1 {
							cursor: pointer;
						}
					}

					.buttonr {
						position: absolute;
						left: 13vw;
						bottom: 6vh;
					}

					.buttonr1 {
						position: absolute;
						left: 13vw;
						bottom: -3.5vh;
					}

					.data-b1-num {
						position: absolute;
						left: 16vw;
						bottom: 6vh;
						cursor: pointer;
					}

					.data-b1-num1 {
						position: absolute;
						left: 16vw;
						bottom: -3.5vh;
						cursor: pointer;
					}

					.data-b1-num2 {
						position: absolute;
						left: 33.5vw;
						bottom: 3vh;
						cursor: pointer;
						width: 4vw;
						text-align: center;
					}

					.data-b1-num3 {
						position: absolute;
						left: 45.8vw;
						bottom: 3vh;
						cursor: pointer;
						width: 4vw;
						text-align: center;
					}

					.rt-t1 {
						width: 4vw;
						text-align: center;
						position: absolute;
						left: 37.5vw;
						bottom: -9vh;
						line-height: 2.5vh;
					}

					.data-b1-le {
						position: absolute;
						left: 33vw;
						bottom: -6vh;
						width: 4vw;
					}

					.rb-ma {
						width: 7vw;
						height: 8vh;
						background-color: #001b2860;
						border: solid 1px #236f8d;
						position: absolute;
						left: 42vw;
						bottom: -6vh;

						.rb-ma-row {
							width: 100%;
							height: 2.66vh;
							line-height: 2.66vh;
							text-align: center;

							.text {
								width: 4vw;
								text-align: right;
								overflow: hidden;
							}

							.ipt {
								width: 3vw;
								text-align: center;
								cursor: pointer;
								color: #01ffba;
							}
						}
					}

					.otherhis {
						position: absolute;
						left: 27vw;
						bottom: -3vh;
						cursor: pointer;
						width: 4vw;
						text-align: center;
						font-size: 1vw;
					}

					.buttonx4 {
						position: absolute;
						left: 42vw;
						bottom: 3.5vh;
					}

					.data-b1-btn {
						width: 3vw;
						height: 2vh;
						text-align: center;
						line-height: 2vh;
						color: #d7f9ff;
						font-size: .7vw;
						border-radius: 2px;
						background-color: #0ef7ff20;
						position: absolute;
						left: 22.5vw;
						bottom: 3.5vh;
						cursor: pointer;
					}
				}

				.data-le {
					width: 5vw;
					height: 8vh;
					background-color: #001b2860;
					border: solid 1px #236f8d;

					.le1 {
						height: 4vh;
						width: 100%;
						box-sizing: border-box;
						border-bottom: 1px solid #8aeaff20;

						.le-tit {
							font-size: .7vw;
							color: #8aeaff;
							text-align: center;
							height: 2vh;
							line-height: 2vh;
							overflow: hidden;
						}

						.le-tit1 {
							color: #2fc3e3;
							font-size: .7vw;
							text-align: center;
							height: 2vh;
							line-height: 2vh;
							cursor: pointer;
							color: #01ffba;
						}
					}
				}

				.data-le1 {
					font-size: .7vw;
					color: #8aeaff;
					padding-left: 0.3vw;
					padding-top: 1vh;
					text-align: center;

					.le-tit1 {
						cursor: pointer;
					}
				}

				.data-le2 {
					font-size: .7vw;
					color: #8aeaff;
					padding-left: 1vw;
					margin-top: -5vh;
					text-align: center;

					.le-tit1 {
						cursor: pointer;
						color: #01ffba;
					}
				}

				.data-le3 {
					padding-left: 1vw;
					height: calc(2vh + 4px);
					position: relative;
					padding-top: 3vh;
				}

				.data-le4 {
					padding-left: 1.5vw;
					width: 2.5vw;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					padding-top: 3vh;
					cursor: pointer;
					text-align: center;
				}

				.data-le5 {
					padding-top: 8vh;
					margin-left: -4vw;
					width: 3vw;
					text-align: center;
					line-height: 2.5vh;

					.le-tit {
						overflow: hidden;
						width: 3vw;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					.le-tit1 {
						cursor: pointer;
						color: #01ffba;
					}
				}

				.data-le6 {
					width: 22vw;
					height: 9vh;
					border-left: solid 1px #236f8d;
					border-top: solid 1px #236f8d;
					margin-top: -1vh;
					box-sizing: border-box;
					margin-right: -1vw;

					.le6-row1-le {
						width: 1.833vw;
						text-align: center;
						height: inherit;
						border-right: solid 1px #236f8d;
						border-bottom: solid 1px #236f8d;
						line-height: 4.5vh;
						box-sizing: border-box;
					}

					.le6-row1-list {
						width: 20.167vw;
						height: 2.25vh;

						div {
							width: 1.833vw;
							height: inherit;
							border-right: solid 1px #236f8d;
							border-bottom: solid 1px #236f8d;
							box-sizing: border-box;
							text-align: center;
							cursor: pointer;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							line-height: 2.25vh;
						}

						.tit {
							cursor: auto;
						}
					}
				}

				.buttonx {
					width: 1vw;
					height: 2vh;
					border: 2px solid rgb(217, 243, 145);
					text-align: center;
					line-height: 2vh;
					color: #fff;
					cursor: pointer;
				}
			}

			.param11-row-bottom {
				width: 49vw;
				margin: 0 auto;
				position: absolute;
				bottom: -13vh;
				left: 2vw;
				font-size: 0.7vw;
				color: #2fc3e3;

				.data-le6 {
					width: 22vw;
					height: 9vh;
					border-left: solid 1px #236f8d;
					border-top: solid 1px #236f8d;
					box-sizing: border-box;

					.le6-row1-le {
						width: 1.833vw;
						text-align: center;
						height: inherit;
						border-right: solid 1px #236f8d;
						border-bottom: solid 1px #236f8d;
						line-height: 4.5vh;
						box-sizing: border-box;
					}

					.le6-row1-list {
						width: 20.167vw;
						height: 2.25vh;

						div {
							width: 1.833vw;
							height: inherit;
							border-right: solid 1px #236f8d;
							border-bottom: solid 1px #236f8d;
							box-sizing: border-box;
							text-align: center;
							cursor: pointer;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							line-height: 2.25vh;
						}

						.tit {
							cursor: auto;
						}
					}
				}

				.bottom-yh {
					width: 8vw;
					height: 3vh;
					text-align: center;
					line-height: 3vh;
					background-color: #11f7ff20;
					border-radius: 2px;
					font-size: 1vw;
					color: #ffffff;
					position: absolute;
					right: 0;
					bottom: 2vh;
					cursor: pointer;
				}
			}

			// .tab-row {
			//   width: 992px;
			//   height: 569px;
			//   margin: 15px 15px 34px 15px;

			//   .row1 {
			//     margin-top: 20px;
			//     margin-left: 10px;

			//     .row1-1 {
			//       margin-top: -10px;
			//       margin-left: 0px;
			//       margin-right: 5px;
			//       width: 75px;
			//       height: 91px;
			//       border: solid 1px #bbd6e5;
			//       text-align: center;
			//       line-height: 91px;
			//       position: absolute;
			//     }
			//     .row1-3 {
			//       margin-top: -10px;
			//       margin-left: 80px;
			//       margin-right: 5px;
			//       width: 65px;
			//       height: 91px;
			//       // border: solid 1px #bbd6e5;
			//       text-align: center;
			//       line-height: 91px;
			//       position: absolute;
			//     }
			//     .row1-3-1 {
			//       margin-top: 20px;
			//       margin-left: 80px;
			//       margin-right: 5px;
			//       width: 65px;
			//       height: 91px;
			//       // border: solid 1px #bbd6e5;
			//       text-align: center;
			//       line-height: 91px;
			//       position: absolute;
			//     }
			//     .row1-4 {
			//       margin-top: -50px;
			//       margin-left: -525px;
			//       width: 65px;
			//       height: 91px;
			//       // position: absolute;
			//     }
			//     .row1-4-1 {
			//       margin-top: -45px;
			//       margin-left: 175px;
			//       width: 65px;
			//       height: 91px;
			//       // position: absolute;
			//     }
			//     .row1-5 {
			//       margin-top: 24px;
			//       margin-left: 180px;
			//       width: 130px;
			//       height: 45px;
			//       border: solid 1px #bbd6e5;
			//       text-align: center;
			//       line-height: 45px;
			//     }
			//     .row1-6 {
			//       margin-top: 20px;
			//       margin-left: 395px;
			//       width: 70px;
			//       height: 40px;
			//       text-align: center;
			//       line-height: 40px;
			//     }
			//     .row1-7 {
			//       margin-top: -70px;
			//       margin-left: -20px;
			//       width: 70px;
			//       height: 40px;
			//       text-align: center;
			//       line-height: 40px;
			//     }
			//     .row1-8 {
			//       margin-top: -40px;
			//       margin-left: 88px;
			//       width: 60px;
			//       height: 40px;
			//       text-align: center;
			//       line-height: 40px;
			//     }
			//     .row1-9 {
			//       margin-top: -40px;
			//       margin-left: 190px;
			//       width: 60px;
			//       height: 40px;
			//       text-align: center;
			//       line-height: 40px;
			//     }
			//     .row1-10 {
			//       margin-top: -40px;
			//       margin-left: 260px;
			//       width: 60px;
			//       height: 40px;
			//       text-align: center;
			//       line-height: 40px;
			//     }
			//     .row1-11 {
			//       margin-top: -65px;
			//       margin-left: 145px;
			//       width: 140px;
			//       height: 40px;
			//       text-align: center;
			//       line-height: 40px;
			//     }
			//     .row1-12 {
			//       margin-top: -40px;
			//       margin-left: 300px;
			//       width: 70px;
			//       height: 40px;
			//       text-align: center;
			//       line-height: 40px;
			//     }

			//     .row1-13 {
			//       margin-top: -25px;
			//       margin-left: 255px;
			//       width: 70px;
			//       height: 40px;
			//       text-align: center;
			//       line-height: 40px;
			//     }
			//     .row1-14 {
			//       margin-top: -210px;
			//       margin-left: 585px;
			//       width: 140px;
			//       height: 40px;
			//       text-align: center;
			//       line-height: 40px;
			//     }
			//     .row1-15 {
			//       margin-top: -30px;
			//       margin-left: 830px;
			//       width: 140px;
			//       height: 40px;
			//       text-align: center;
			//       line-height: 40px;
			//     }
			//     .row1-16 {
			//       margin-top: -90px;
			//       margin-left: 165px;

			//       width: 140px;
			//       height: 90px;
			//       text-align: center;
			//       line-height: 40px;
			//       border: solid 1px #bbd6e5;
			//       .div1 {
			//         margin-top: -5px;
			//         margin-left: -5px;
			//         width: 110px;
			//         height: 30.3px;
			//         color: #8aeaff;
			//       }
			//       .div2 {
			//         margin-top: -30px;
			//         margin-left: 75px;
			//         width: 80px;
			//         height: 30.3px;
			//         color: #fff;
			//       }
			//       .div11 {
			//         margin-top: -5px;
			//         margin-left: -5px;
			//         width: 110px;
			//         height: 30.3px;
			//         color: #8aeaff;
			//       }
			//       .div22 {
			//         margin-top: -30px;
			//         margin-left: 75px;
			//         width: 80px;
			//         height: 30.3px;
			//         color: #fff;
			//       }
			//       .div111 {
			//         margin-top: -5px;
			//         margin-left: 8px;
			//         width: 110px;
			//         height: 30.3px;
			//         color: #8aeaff;
			//       }
			//       .div222 {
			//         margin-top: -30px;
			//         margin-left: 70px;
			//         width: 90px;
			//         height: 30.3px;
			//         color: #fff;
			//       }
			//     }
			//     .row1-17 {
			//       margin-top: 63px;
			//       margin-left: -20px;
			//       width: 470px;
			//       height: 101px;
			//       text-align: center;
			//       line-height: 100px;
			//       border: solid 1px #bbd6e5;
			//       font-family: PingFang-SC-Regular;
			//       font-size: 13px;
			//       font-weight: normal;
			//       font-stretch: normal;
			//       line-height: 25px;
			//       letter-spacing: -1px;
			//       color: #8aeaff;
			//       .row-17-1 {
			//         margin-top: -0px;
			//         width: 38px;
			//         height: 101px;
			//         text-align: center;
			//         line-height: 101px;
			//         border-right: solid 1px #bbd6e5;
			//       }
			//       .row-17-2 {
			//         margin-top: 0px;
			//         width: 38px;
			//         height: 101px;
			//         border-right: solid 1px #bbd6e5;
			//       }
			//       .row-17-3 {
			//         margin-top: 0px;
			//         width: 38px;
			//         height: 101px;
			//       }
			//     }
			//     .row1-19 {
			//       margin-top: -513px;
			//       margin-left: 538px;
			//       width: 400px;
			//       height: 101px;
			//       text-align: center;
			//       line-height: 100px;
			//       border: solid 1px #bbd6e5;
			//       font-family: PingFang-SC-Regular;
			//       font-size: 13px;
			//       font-weight: normal;
			//       font-stretch: normal;
			//       line-height: 25px;
			//       letter-spacing: -1px;
			//       color: #8aeaff;
			//       .row-19-1 {
			//         margin-top: -411px;
			//         width: 34px;
			//         height: 101px;
			//         text-align: center;
			//         line-height: 101px;
			//         border-right: solid 1px #bbd6e5;
			//       }
			//       .flex{
			//         margin-left: 70px;
			//       }
			//       .row-19-2 {
			//         margin-top: -411px;
			//         // margin-left: 30px;
			//        width: 32px;
			//         height: 101px;
			//         border-right: solid 1px #bbd6e5;
			//         text-align: center;
			//         font-size: .6vw;
			//        div{
			//           overflow: hidden;
			//         text-overflow: ellipsis;
			//         white-space: nowrap;
			//        }
			//       }
			//       .row-19-3 {
			//         margin-top: -411px;
			//         // margin-left: 29px;
			//         // width: 38px;
			//         height: 101px;
			//         text-align: center;
			//       }
			//     }

			//     .row1-18 {
			//       margin-top: -70px;
			//       margin-left: -190px;
			//       width: 140px;
			//       height: 40px;
			//       text-align: center;
			//       line-height: 40px;
			//     }
			//     .startbutton {
			//       text-align: center;
			//       width: 45px;
			//       height: 22px;
			//       background-color: #89b5ce;
			//       border-radius: 2px;
			//       margin-top: -120px;
			//       margin-left: 410px;
			//       font-size: 14px;
			//       font-weight: normal;
			//       font-stretch: normal;
			//       line-height: 20px;
			//       letter-spacing: 0px;
			//       color: #0a4c62;
			//     }
			//     .startbutton2 {
			//       text-align: center;
			//       width: 65px;
			//       line-height: 20px;
			//       background-color: #89b5ce;
			//       border-radius: 2px;
			//       margin-top: -15px;
			//       margin-left: 91px;
			//       font-family: PingFang-SC-Regular;
			//       font-size: 14px;
			//       font-weight: normal;
			//       font-stretch: normal;
			//       line-height: 20px;
			//       letter-spacing: 0px;
			//       color: #0a4c62;
			//     }
			//     .startbutton3 {
			//       text-align: center;
			//       width: 144px;
			//       height: 32px;
			//       background-color: #11f7ff;
			//       border-radius: 2px;
			//       margin-top: 210px;
			//       margin-left: 150px;
			//       font-size: 16px;
			//       font-weight: normal;
			//       font-stretch: normal;
			//       line-height: 32px;
			//       letter-spacing: 0px;
			//       color: #0a4c62;
			//     }
			//     .title1 {
			//       height: 22.75px;
			//       text-align: center;
			//       font-family: PingFang-SC-Regular;
			//       font-size: 14px;
			//       font-weight: normal;
			//       font-stretch: normal;
			//       line-height: 22.75px;
			//       letter-spacing: 0px;
			//       color: #8aeaff;
			//     }
			//     .title11 {
			//       height: 22.75px;
			//       text-align: center;
			//       font-family: PingFang-SC-Regular;
			//       font-size: 14px;
			//       font-weight: normal;
			//       font-stretch: normal;
			//       line-height: 22.75px;
			//       letter-spacing: 0px;
			//       color: #fff;
			//     }
			//     .title2 {
			//       margin-top: -20px;
			//       margin-left: 285px;
			//       height: 22.75px;
			//       text-align: center;
			//       font-family: PingFang-SC-Regular;
			//       font-size: 14px;
			//       font-weight: normal;
			//       font-stretch: normal;
			//       line-height: 22.75px;
			//       letter-spacing: 0px;
			//       color: #fff;
			//     }
			//     .buttonx {
			//       width: 24px;
			//       height: 24px;
			//       border: 2px solid rgb(217, 243, 145);
			//       text-align: center;
			//       line-height: 24px;
			//       margin-top: 85px;
			//       margin-left: 210px;
			//       color: #fff;
			//     }
			//     .buttonx1 {
			//       width: 24px;
			//       height: 24px;
			//       border: 2px solid rgb(217, 243, 145);
			//       text-align: center;
			//       line-height: 24px;
			//       margin-top: -17px;
			//       margin-left: 790px;
			//       color: #fff;
			//     }
			//     .buttonr {
			//       width: 24px;
			//       height: 24px;
			//       border: 2px solid rgb(217, 243, 145);
			//       text-align: center;
			//       line-height: 24px;
			//       margin-top: 15px;
			//       margin-left: 210px;
			//       color: #fff;
			//     }
			//     .row1-2 {
			//       width: 47vw;
			//       height: 35vh;
			//       // border-bottom: 1px solid #98A4B1;
			//       background: url("~@/assets/images/rfl1-4.png") no-repeat 0 0 ;
			//       background-size: 100% 100%;
			//       position: relative;
			//       margin: 0 auto;
			//     }
			//   }
			// }
		}
	}
</style>
